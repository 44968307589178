import { Ajax } from "./../../core/ajax";

export class BaseApiConector extends Ajax {
  constructor(url = "") {
    super();
    this.path = url;
  }
  async get(parameters = {}) {
    const data = await this.__run(parameters, "get");
    //const path = this._setPath(parameters);
    //localStorage.setItem(path, btoa(JSON.stringify(data)));
    return data;
  }
  /**
   * @description envia por medio del metodo post los datos asignados en parameters
   * @param {*} parameters
   * @returns Promise
   */
  async post(parameters = {}, hasFiles = false) {
    return this.__run(parameters, "post", hasFiles);
  }
  /**
   * @description envia por medio del metodo put los datos asignados en parameters
   * @param {*} parameters
   * @returns Promise
   */
  async put(parameters = {}) {
    return this.__run(parameters, "put");
  }
  /**
   * @description envia por medio del metodo delete los datos asignados en parameters
   * @param {*} parameters
   * @returns Promise
   */
  async delete(parameters = {}) {
    return this.__run(parameters, "delete");
  }
  setPath(path) {
    this.path = path;
  }
  async __run(parameters, method, hasFiles, headers = {}) {
    if (!hasFiles) {
      headers = {
        ...headers,
        "Content-type": "application/json",
      };
    }
    const response = await new Ajax().fetchData(
      this.path,
      parameters,
      method,
      this.headers,
      !hasFiles,
    );
    //this.hasError(response);
    return response;
  }
}
