import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon,
} from "react-feather";
import NavItem from "./NavItem";
import { ConditionalWall } from "./../../../components/FilterWall/ConditionalWall";
import { LoginManager } from "./../../../utils/LoginManager";

const items = [
  {
    href: "/app/dashboard",
    icon: "home",
    title: "Inicio",
  },
  {
    href: "/app/usuarios",
    icon: "users",
    title: "Usuarios",
    profile: [1],
  },
  {
    href: "/app/credit",
    icon: "dollar-sign",
    title: "Credito",
    profile: [1],
  },
  {
    href: "/app/credit/loans",
    icon: "dollar-sign",
    title: "Credito",
    profile: [3],
  },
  {
    href: "/app/customers",
    icon: UsersIcon,
    title: "Clientes",
    profile: [1],
  },
  {
    href: "/app/middlemen",
    icon: ShoppingBagIcon,
    title: "Comisionistas",
    profile: [1],
  },
  {
    href: "/app/credit/loans",
    icon: "university",
    title: "Prestamos",
    profile: [1, 3],
  },
  {
    href: "/app/customer/request",
    icon: "university",
    title: "Prestamos",
    profile: [2],
  },
  {
    href: "/app/payments",
    icon: "credit-card",
    title: "Pagos",
    profile: [1],
  },
  {
    href: "/app/settings",
    icon: "cog",
    title: "Configuracion",
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const me = new LoginManager().getToken().user;
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={"/static/logo3.svg"}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          style={{ textTransform: "capitalize" }}
          variant="h5">
          {me.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          style={{ textTransform: "capitalize" }}>
          {me.profile.profileName}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item, key) => (
            <ConditionalWall
              key={key}
              condition={
                item.profile === undefined ||
                item.profile.includes(me.profileID)
              }>
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            </ConditionalWall>
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
