import React, { Component } from "react";
import { colors } from "@material-ui/core";
import "./scss/all.min.scss";
/**
 * @deprecated
 */
export class FontIcon extends Component {
  render() {
    const { iconName, className, ...rest } = this.props;
    return (
      <i
        className={`fa ${iconName} ${className}`}
        style={{ color: colors.indigo[500] }}
        {...rest}
      />
    );
  }
}
export function FaIcon({ icon, ...rest }) {
  return <FontIcon iconName={`fa-${icon}`} {...rest} />;
}
