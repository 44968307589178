import React, { useState } from "react";
import {
  FormikSpace,
  SmartFormik,
} from "src/components/Containers/SmartFormik";
import { CreditModel } from "./../../../utils/models/CreditModel";
import Page from "./../../../components/Page";
import { PanelContainer } from "./../../../components/Containers/PanelContainer";
import { Box, Button, makeStyles } from "@material-ui/core";
import { CreditController } from "./../../../utils/controller/CreditController";
import { optionalFn } from "src/core/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export function CreditForm({ onSave }) {
  const classes = useStyles();
  const model = new CreditModel();
  const [loading, setLoading] = useState(0);
  return (
    <Page title={"Crear Credito"} className={classes.root}>
      <PanelContainer maxWidth="lg" title={"Crear Credito"}>
        <SmartFormik
          model={model}
          onSubmit={(ev) => {
            setLoading(1);
            const fetch = new CreditController();
            ev.factor = ev.factor / 100 + 1;
            fetch.post(ev).then((response) => {
              if (response.code > 200) {
                alert("Parece que el servidor esta en mantenimiento");
                return false;
              }
              optionalFn(onSave)(response);
            });
          }}>
          {(mods) => (
            <FormikSpace>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  disabled={Boolean(loading)}
                  type="submit"
                  color="primary"
                  variant="contained">
                  Guardar
                </Button>
              </Box>
            </FormikSpace>
          )}
        </SmartFormik>
      </PanelContainer>
    </Page>
  );
}
