import React, { useEffect, useState } from "react";
import { AsyncTable } from "./../../../components/Tables/Table";
import Page from "./../../../components/Page";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { PaymentController } from "./../../../utils/controller/PaymentController";
import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@material-ui/core";
import { PaymentModel } from "./../../../utils/models/PaymentModel";
import {
  FormikSpace,
  SmartFormik,
} from "src/components/Containers/SmartFormik";
import { SimpleButton } from "src/components/Inputs/SimpleInput";
import { optionalFn, validInputDate } from "src/core/helpers";

import "./scss/loan.scss";
import { GridItem } from "src/components/Grid/Grid";
import { Money } from "./../../../components/Formats/FormatNumbers";
import { EditPaymentModel } from "./../../../utils/models/EditPayment";
import { LoginManager } from "./../../../utils/LoginManager";
import { MovementController } from "./../../../utils/controller/MovementController";
import { CustomModal } from "src/components/CustomModal/CustomModal";

export function PaymentTable({ id }) {
  const [key, setKey] = useState(0);

  return (
    <Page title="Pagos">
      <PanelContainer title="Pagos" className="loanTable" maxWidth="xl">
        <GridItem>
          <SimplePaymentTable id={id} key={key} />
          <PaymentModal
            id={id}
            onSubmit={() => {
              setKey(key + 1);
            }}
          />
        </GridItem>
      </PanelContainer>
    </Page>
  );
}
export function PaymentForm({ id, onSubmit }) {
  const model = new PaymentModel();
  return (
    <>
      <SmartFormik
        model={model}
        onSubmit={(content) => {
          const fetch = new PaymentController();
          fetch
            .post({
              movementID: id,
              payment: content.payment,
              folio: content.folio,
            })
            .then(() => {
              optionalFn(onSubmit)(content);
            });
        }}>
        {(mods) => (
          <FormikSpace>
            <SimpleButton disabled={mods.isSubmitting}>Siguiente</SimpleButton>
          </FormikSpace>
        )}
      </SmartFormik>
    </>
  );
}
export function PaymentModal({ id, onSubmit }) {
  const [open, toggle] = useState(0);
  return (
    <Box display="flex" justifyContent="flex-end" p={2}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          toggle(1);
        }}>
        Añadir Pago
      </Button>
      <Modal
        open={Boolean(open)}
        onClose={() => {
          toggle(0);
        }}>
        <>
          <PanelContainer title="Registro de pago" maxWidth="sm">
            <PaymentForm
              id={id}
              onSubmit={(content) => {
                toggle(0);
                optionalFn(onSubmit)(content);
              }}
            />
          </PanelContainer>
        </>
      </Modal>
    </Box>
  );
}
export function SimplePaymentTable({ id, ...rest }) {
  const link = new PaymentController();
  const [reload, setID] = useState(0);
  return (
    <AsyncTable
      key={reload}
      {...rest}
      filler={() => {
        return link.getPayments(id);
      }}
      titles={{
        folio: "folio",
        expectedPaymentDate: "Fecha a pagar",
        rate: "Tarifa",
        surcharge: "Plazo vencido",
        actualPayment: "Pago",
        actualPaymentDate: "Fecha de pago",
        status: "Estado",
      }}
      format={(props) => {
        return [
          props.folio?.toUpperCase(),
          props.expectedPaymentDate,
          <Money number={props.rate} />,
          <Money number={props.surcharge} />,
          <MovementModal price={props.actualPayment} id={props.id} />,
          props.actualPaymentDate,
          <ModalEditPayment
            status={props.status}
            {...props}
            onSubmit={() => {
              setID(reload + 1);
            }}
          />,
        ];
      }}
    />
  );
}
export function ModalEditPayment({ status, onSubmit, ...rest }) {
  const [open, toggle] = useState();
  let model = new EditPaymentModel();
  model.setValues(rest);
  return (
    <div>
      <Chip
        label={status}
        className={status}
        onClick={() => {
          const lm = new LoginManager();
          if (
            status === "pendiente" &&
            lm.hasPermission([1]) &&
            !rest.actualPayment
          ) {
            toggle(1);
          }
        }}
      />
      <Modal
        open={Boolean(open)}
        onClose={() => {
          toggle(0);
        }}>
        <div>
          <PanelContainer maxWidth="sm" title="Editar Pago pendiente">
            <SmartFormik
              model={model}
              onSubmit={(values) => {
                const fetch = new PaymentController();
                fetch.put(rest.id, values).then((response) => {
                  optionalFn(onSubmit)(response);
                  toggle(0);
                });
              }}>
              {(mods) => {
                return (
                  <FormikSpace>
                    <SimpleButton>OK</SimpleButton>
                  </FormikSpace>
                );
              }}
            </SmartFormik>
          </PanelContainer>
        </div>
      </Modal>
    </div>
  );
}

function MovementModal({ price, id }) {
  const [open, toggle] = useState();
  return (
    <>
      <Button
        style={{ border: "1px solid" }}
        onClick={() => {
          toggle(1);
        }}>
        <Money number={price} />
      </Button>
      <CustomModal
        open={open}
        onClose={() => {
          toggle(0);
        }}>
        <div>
          <PanelContainer title="Historial de pagos">
            <PaymentHistory id={id} />
          </PanelContainer>
        </div>
      </CustomModal>
    </>
  );
}
export function PaymentHistory({ id }) {
  const [movements, setMovements] = useState([]);
  const loadHistory = () => {
    const history = new MovementController();
    history.get({ paymentID: id }).then((resp) => {
      console.log(resp);
      setMovements(resp.data);
    });
  };
  useEffect(loadHistory, [id]);
  return (
    <List
      style={{
        width: "600px",
        maxWidth: "80vw",
        boxShadow: "1px 1px 3px #eee",
      }}>
      {movements.map((item) => (
        <ListItem>
          <ListItemText
            primary={
              <h4>
                Pago:
                <Money number={item.amount} />
              </h4>
            }
            secondary={
              <div style={{ background: "#eee" }}>
                <h5>Folio:{item.folio}</h5>
                <div>Creado el:{validInputDate(item.created_at)}</div>
              </div>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
