import { BaseModel } from "./BaseModel";
export class EditPaymentModel extends BaseModel {
  values = {
    expectedPaymentDate: {
      title: "Fecha a pagar",
      type: "date",
    },
    rate: {
      title: "Tarifa",
      type: "number",
    },
    surcharge: {
      title: "Recargo",
      type: "number",
    },
  };
}
