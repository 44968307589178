import React, { useState } from "react";
import { CreditTable } from "./linkTable";
import { GridContainer, GridItem } from "./../../../components/Grid/Grid";
import { Container } from "@material-ui/core";
import { LinkButton } from "./../../../components/Links/Links";
import { CreditForm } from "./../register/CreditForm";
export function CreditView() {
  const [updatekey, setKey] = useState(0);
  return (
    <Container maxWidth="lg">
      <GridContainer>
        <GridItem>
          <LinkButton href="/app/credit/loans">Prestamos</LinkButton>
        </GridItem>
        <GridItem md={4}>
          <CreditForm
            key={"no deberia-" + updatekey}
            onSave={() => {
              setKey(updatekey + 1);
            }}
          />
        </GridItem>
        <GridItem md={8}>
          <CreditTable key={updatekey} />
        </GridItem>
      </GridContainer>
    </Container>
  );
}
