import { BaseController } from "./BaseController";
import firebase from "./../../utils/firebase";
import { LoginManager } from "./../LoginManager";
export class DevicesController extends BaseController {
  path = "devices";
  async register() {
    const lm = new LoginManager();
    if (Boolean(lm.isLogged()) === false) {
      return false;
    }
    const me = lm.getToken().user;
    const register = firebase.messaging();
    register
      .requestPermission()
      .then(function () {
        console.log("Notification Permission");
        return register.getToken();
      })
      .then((token) => {
        this.post({ token, userID: me.id });
        register.onMessage(function (payload) {
          console.log("Message received. ", payload);

          const notificationTitle = payload.notification.title;
          let url = "/";
          if (payload?.data) {
            url = payload?.data.url;
          }
          const notificationOptions = {
            body: payload.notification.body,
            icon: payload.notification.image,
            path: url,
          };

          if (!("Notification" in window)) {
            console.log("This browser does not support system notifications");
          }

          // Let's check whether notification permissions have already been granted
          else {
            if (Notification.permission === "granted") {
              // If it's okay let's create a notification
              try {
                var notification = new Notification(
                  notificationTitle,
                  notificationOptions,
                );
                notification.onclick = function (event) {
                  event.preventDefault(); //prevent the browser from focusing the Notification's tab
                  window.open(notificationOptions.path, "_blank");
                  notification.close();
                };
              } catch (err) {
                try {
                  //Need this part as on Android we can only display notifications thru the serviceworker
                  navigator.serviceWorker.ready.then(function (registration) {
                    registration.showNotification(
                      notificationTitle,
                      notificationOptions,
                    );
                  });
                } catch (err1) {
                  console.log(err1.message);
                }
              }
            }
          }
        });
      })
      .catch(function (reason) {});
  }
}
