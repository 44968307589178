import React, { useEffect, useState } from "react";
import { SimpleSelector } from "src/components/Inputs/SimpleInput";
import { CreditController } from "./../../../utils/controller/CreditController";
export function CreditSelector({ ...rest }) {
  const [options, setOptions] = useState([]);
  const loadOptions = () => {
    const fetch = new CreditController();
    fetch
      .get({ needed: ["id", "creditName", "factor", "timePeriodInDays"] })
      .then((response) => {
        setOptions(
          response.data.map((item) => ({
            title: `${item.creditName} (Interes ${item.factor}  a ${item.timePeriodInDays} dias)`,
            id: item.id,
            ...item,
          })),
        );
      });
  };
  useEffect(loadOptions, []);
  return <SimpleSelector title="Clientes" options={options} {...rest} />;
}
export function CreditStatusSelector({ ...rest }) {
  const [options, setOptions] = useState([]);
  const loadOptions = () => {
    const fetch = new CreditController();
    fetch.getStatus().then((response) => {
      setOptions(
        response.data.map((item) => ({
          title: `${item.name.toUpperCase()}`,
          id: item.id,
        })),
      );
    });
  };
  useEffect(loadOptions, []);
  return <SimpleSelector title="Estatus" options={options} {...rest} />;
}
