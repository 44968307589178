import React, { useState } from "react";
import {
  FormikSpace,
  SmartFormik,
} from "src/components/Containers/SmartFormik";
import { SimpleButton } from "src/components/Inputs/SimpleInput";
import { WizardForm, WizardPage } from "src/components/Wizard/WizardForm";
import { MiddlemanModel } from "src/utils/models/MiddlemanModel";
import { SimpleUserRegister } from "./../../customer/Register/simpleUserRegister";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { Typography } from "@material-ui/core";
import { SplashScreen } from "./../../../components/Containers/SplashScreen";
import { UsersController } from "src/utils/controller/UsersController";
import { MiddlemanController } from "src/utils/controller/MiddlemanController";
import { useNavigate } from "react-router-dom";
import { PanelContainer } from "src/components/Containers/PanelContainer";
export function MiddlemanWizard() {
  const [state, setState] = useCState({ user: {}, middleman: {} });
  const [page, setPage] = useState(0);
  const [splashMsg, setMsg] = useState("");
  const navigate = useNavigate();
  return (
    <PanelContainer title="Registro de comisionista">
      <SplashScreen open={Boolean(splashMsg)}>{splashMsg}</SplashScreen>
      <WizardForm page={page}>
        <WizardPage title="Datos de usuario">
          <SimpleUserRegister
            onSubmit={(ev) => {
              setState({ user: ev });
              setPage(page + 1);
            }}
          />
        </WizardPage>
        <WizardPage title="Informacion de comisionista">
          <MiddlemanForm
            onSubmit={(ev) => {
              setState({ middleman: ev });
              setPage(page + 1);
            }}
          />
        </WizardPage>
        <WizardPage title={"Competar Registro"}>
          <Typography variant="h3">
            Haz completado el registro del comisionista.
          </Typography>
          <SimpleButton
            onClick={() => {
              save(state, setMsg).then(() => {
                navigate("/app/middlemen");
              });
            }}>
            Guardar
          </SimpleButton>
        </WizardPage>
      </WizardForm>
    </PanelContainer>
  );
}
export function MiddlemanForm({ onSubmit }) {
  return (
    <SmartFormik onSubmit={onSubmit} model={new MiddlemanModel()}>
      {(mods) => (
        <FormikSpace>
          <SimpleButton disabled={mods.isSubmiting}>Siguiente</SimpleButton>
        </FormikSpace>
      )}
    </SmartFormik>
  );
}
async function save(content, hook) {
  hook("Creando Usuario");
  const user = new UsersController();
  const middleman = new MiddlemanController();
  content.user.profileID = 3;
  const userRes = await user.post(content.user);
  content.middleman.userID = userRes.user.id;
  hook("Registrando Comisionista");
  await middleman.post(content.middleman);
  hook("Redirigiendo a pagina de comisionistas");
}
