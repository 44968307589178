import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Password from "./Password";
import { GridItem, GridContainer } from "src/components/Grid/Grid";
import { ClientListData } from "src/views/customer/CustumerView";
import { InfoController } from "./../../../utils/controller/InfoController";
import { LoginManager } from "./../../../utils/LoginManager";
import { ConditionalWall } from "src/components/FilterWall/ConditionalWall";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const [id, setId] = useState();
  const lm = new LoginManager();
  const validUser = lm.hasPermission([2]);
  const loadInfo = () => {
    if (validUser) {
      const info = new InfoController();
      info.me().then((id) => {
        setId(id);
      });
    }
  };
  useEffect(loadInfo, []);
  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="xl">
        <GridContainer>
          <ConditionalWall condition={validUser}>
            <GridItem md={4}>
              <ClientListData id={id} />
            </GridItem>
          </ConditionalWall>

          <GridItem md={validUser ? 8 : 12}>
            <Box mt={3}>
              <Password />
            </Box>
          </GridItem>
        </GridContainer>
      </Container>
    </Page>
  );
};

export default SettingsView;
