import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { GridContainer, GridItem } from "src/components/Grid/Grid";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { InfoController } from "./../../utils/controller/InfoController";
import { useCState } from "./../../utils/hooks/simpleHooks";
import { envURL } from "./../../core/helpers";
import { SimpleAccordion } from "./../../components/Containers/SimpleAccordion";
import { ImageBackdrop } from "./../../components/Images/Image";
import { LoanList } from "../credit/views/loanView";
import "./scss/CustomerView.scss";
import { SmartList } from "./../../components/Inputs/ToggleableInputs";
import { ReferenceModel } from "./../../utils/models/ReferenceModel";
import { ReferenceController } from "./../../utils/controller/ReferenceController";
import { GuarantorModel } from "./../../utils/models/GuarantorModel";
import { GuarantorController } from "./../../utils/controller/GuarantorController";
import { SimpleFileInput } from "./../../components/Inputs/SimpleInput";
import { ImageController } from "./../../utils/controller/ImageController";
import { InfoModel } from "./../../utils/models/InfoModel";
import { ConditionalWall } from "src/components/FilterWall/ConditionalWall";
import { LinkTable } from "../credit/views/linkTable";
import { useParams } from "react-router";
export function CustomerView({ id }) {
  return (
    <Container maxWidth="xl" className="customerView">
      <GridContainer>
        <GridItem xs={12} md={12} className="LoanList">
          <LoanList infoID={id} />
        </GridItem>
      </GridContainer>
    </Container>
  );
}
export function InnerCustomerView() {
  const { id } = useParams();
  return (
    <Container maxWidth="xl" className="customerView">
      <GridContainer>
        <GridItem xs={12} md={4} className="LoanList">
          <ClientListData id={id} />
        </GridItem>
        <GridItem md={8}>
          <LinkTable extra={{ infoId: id }} />
        </GridItem>
      </GridContainer>
    </Container>
  );
}

export function ClientListData({ id, children }) {
  const [state, setState] = useCState();
  const loadInfo = () => {
    const fetch = new InfoController();
    fetch.show(id).then((response) => {
      setState(response.data);
    });
  };
  useEffect(loadInfo, [id]);
  return (
    <PanelContainer title={"Detalles del cliente"}>
      <GridItem>
        <InfoList {...state} />
      </GridItem>
      <GridItem>
        <SimpleAccordion title="Referencias">
          <ReferencesList refs={state.ref} />
        </SimpleAccordion>
        <SimpleAccordion title="Comprobantes">
          <VouchersList id={id} />
        </SimpleAccordion>
        <SimpleAccordion title="Aval">
          <GuarantorList {...state.guarantor} />
        </SimpleAccordion>
        {children}
      </GridItem>
    </PanelContainer>
  );
}
function VouchersList({ id, type = "info" }) {
  return (
    <List>
      <ListItem>
        <ImageContainer
          id={id}
          url={`/ine.jpg`}
          title={"ine"}
          name={"ine"}
          type={type}
        />
      </ListItem>
      <ListItem>
        <ImageContainer
          id={id}
          url={`/reverso.jpg`}
          title={"Reverso"}
          name={"reverso"}
          type={type}
        />
      </ListItem>
      <ListItem>
        <ImageContainer
          id={id}
          url={`/address.jpg`}
          title={"Comprobante de domicilio"}
          type={type}
        />
      </ListItem>
      <ConditionalWall condition={type === "info"}>
        <ListItem>
          <ImageContainer
            id={id}
            url={`/account.jpg`}
            title={"Ultimo Edo de cuenta"}
            type={type}
          />
        </ListItem>
      </ConditionalWall>
    </List>
  );
}
function ReferencesList({ refs = [] }) {
  return refs.map((item, key) => (
    <div key={key}>
      <Typography variant="h4">Referencia {key + 1}</Typography>
      <SmartList
        model={new ReferenceModel()}
        controller={new ReferenceController()}
        values={item}
      />
    </div>
  ));
}
function GuarantorList(props) {
  return (
    <>
      <SmartList
        controller={new GuarantorController()}
        model={new GuarantorModel()}
        values={props}
      />

      <VouchersList type="guarantor" id={props.id} />
    </>
  );
}
export function InfoList(props) {
  return (
    <SmartList
      controller={new InfoController()}
      model={new InfoModel()}
      values={props}
    />
  );
}
function ImageContainer({ url, title, type, id }) {
  let path = (envURL() + `/images/${type}-${id}`).replace(/api\//, "");
  const [status, setStatus] = useState("");
  let link = path + url;
  let fileName = url.replace(".jpg", "").replace("/", "");
  return (
    <GridContainer style={{ width: "100%" }}>
      <GridItem xs={10}>
        <ImageBackdrop src={link}>
          <Button color="primary" variant="contained">
            {status || title}
          </Button>
        </ImageBackdrop>
      </GridItem>
      <GridItem xs={2}>
        <SimpleFileInput
          listStyle={{ display: "none" }}
          accept="image/jpeg"
          msg={"archivo requerido"}
          name={url}
          onChange={(files) => {
            setStatus("Cargando...");
            const fetch = new ImageController();
            fetch.get({ fkID: id, type, path: url }).then((response) => {
              let content = response.data[0];
              if (typeof content === "undefined") {
                return fetch
                  .post({ fkID: id, type, [fileName]: files[0] })
                  .then((response) => {
                    setStatus("");
                  });
              }
              fetch.put(content.id, { file: files[0] }).then((response) => {
                setStatus("");
              });
            });
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
