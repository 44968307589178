import React, { useState, useEffect } from "react";
import { IconButton, ListItemText, Menu, MenuItem } from "@material-ui/core/";
import { Badge } from "@material-ui/core";
import { NotificationsController } from "./../utils/controller/NotificationsController";
import { UsersController } from "./../utils/controller/UsersController";
import { FaIcon } from "src/components/Icons/FontIcon";
import { useHistory } from "./../utils/hooks/simpleHooks";

export function NotificationBell() {
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const loadNotifications = () => {
    const fetch = new NotificationsController();
    const user = new UsersController().me();
    fetch
      .get({
        sent_msgUserID: user.id,
        sent_msgSeen: 0,
        needs: ["id", "push", "content", "title", "path"],
      })
      .then((response) => {
        setNotifications(
          response.data.map((item) => {
            let msg = item.notificationsPush || "";
            let title = item.notificationsTitle;
            let values = JSON.parse(item.sent_msgContent);
            for (let key in values) {
              const value = values[key];
              msg = msg.replaceAll(`[${key}]`, value);
              title = title.replaceAll(`[${key}]`, value);
            }
            return {
              title,
              msg,
              id: item.sent_msgId,
              path: item.sent_msgPath,
              content: JSON.parse(item.sent_msgContent),
            };
          }),
        );
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(loadNotifications, []);
  return (
    <div>
      <IconButton
        color="inherit"
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget);
        }}>
        <Badge badgeContent={notifications.length} color="secondary">
          <FaIcon icon="bell" style={{ color: "white" }} />
        </Badge>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {notifications.map((item, key) => {
          return (
            <MenuItem
              key={key}
              onClick={() => {
                const fetch = new NotificationsController();
                //console.log(item.path.replace("[id]", item.content.id));
                fetch.put(item.id, { seen: 1 }).then((response) => {
                  setNotifications(
                    notifications.filter((ft) => ft.id !== item.id),
                  );
                  if (item.path)
                    history(
                      "/app/" + item.path.replace("[id]", item.content.id),
                    );
                });
              }}>
              <ListItemText primary={item.title} secondary={item.msg} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
