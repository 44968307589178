import React, { useState } from "react";
import { InfoController } from "./../../../utils/controller/InfoController";
import { AsyncTable } from "./../../../components/Tables/Table";
import Page from "./../../../components/Page";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { SimpleSearchInput } from "./../../../components/Inputs/SimpleInput";
import { LinkButton } from "./../../../components/Links/Links";
import { GridItem } from "src/components/Grid/Grid";
import { Money } from "./../../../components/Formats/FormatNumbers";
import { useHistory } from "./../../../utils/hooks/simpleHooks";
import { Button } from "@material-ui/core";
import { FaIcon } from "src/components/Icons/FontIcon";
export function InfoTable() {
  const user = new InfoController();
  const [search, setSearch] = useState("");
  const history = useHistory();
  return (
    <Page title="Clientes" style={{ marginTop: "20px" }}>
      <PanelContainer title="Clientes">
        <GridItem>
          <LinkButton href="/app/register">Registro</LinkButton>
        </GridItem>
        <GridItem>
          <SimpleSearchInput
            onChange={(value) => {
              setSearch(value);
            }}
          />
          <AsyncTable
            filler={user.get.bind(user)}
            needed={[
              "id",
              "name",
              "surname",
              "email",
              "phones",
              "amountRequired",
            ]}
            search={search}
            titles={{
              id: "ID",
              name: "Nombre",
              surname: "Apellido",
              email: "Correo",
              amountRequired: "Monto",
              action: { title: "Acciones", sort: false },
            }}
            format={({ id, name, surname, email, amountRequired }) => [
              id,
              name,
              surname,
              email,
              <Money number={amountRequired} />,
              <Button
                onClick={() => {
                  history(`/app/customer/profile/${id}`);
                }}>
                <FaIcon icon="edit" />
              </Button>,
            ]}
          />
        </GridItem>
      </PanelContainer>
    </Page>
  );
}
