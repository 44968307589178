import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import { UsersController } from "src/utils/controller/UsersController";
import { GridContainer } from "src/components/Grid/Grid";
import { GridItem } from "src/components/Grid/Grid";
import { SimpleButton, SimpleInput } from "src/components/Inputs/SimpleInput";

const Password = ({ className, ...rest }) => {
  return (
    <Container maxWidth="lg">
      <Card>
        <CardHeader subheader="Actualiza tu Password" title="Password" />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(6, "Minimo 6 caracteres")
                .max(255)
                .required("password es requerido"),
            })}
            onSubmit={(ev, { resetForm }) => {
              resetForm({ password: "" });
              const fetch = new UsersController();
              let id = fetch.me().id;
              fetch.put(id, ev).then((response) => {
                swal(
                  "Cambio de contraseña",
                  "Actualizacion de contraseña completado",
                  "success",
                );
              });
            }}>
            {({ handleSubmit, values, handleChange, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem>
                      <SimpleInput
                        error={Boolean(errors.password)}
                        msg={errors.password}
                        title="Contraseña"
                        type="password"
                        onChange={handleChange}
                        value={values.name}
                        name="password"
                      />
                    </GridItem>
                    <GridItem>
                      <SimpleButton>Guardar</SimpleButton>
                    </GridItem>
                  </GridContainer>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;
