import { BaseModel } from "./BaseModel";
import * as Yup from "yup";
export class CreditModel extends BaseModel {
  values = {
    creditName: {
      title: "Nombre del credito",
      validation: Yup.string().max(255).required("Nombre es requerido"),
    },
    creditType: {
      title: "Tipo de credito",
      validation: Yup.string().max(255).required("Tipo es requerido"),
    },
    description: {
      title: "Descripcion",
      validation: Yup.string().max(255).required("Descripcion es requerida"),
    },

    timePeriodInDays: {
      title: "Duracion de Credito en dias",
      validation: Yup.number()
        .min(0, "la duracion del Credito no puede ser menor a 0")
        .required("periodo es requerido"),
      type: "number",
    },
    factor: {
      title: "Incremento %",
      validation: Yup.number()
        .min(0, "el incremento no puede ser menor a 0")
        .required("Incremento es requerido"),
      type: "number",
    },
  };
}
