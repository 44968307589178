import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAzHQhdInOUs-eEcOOynuLDUjJ57iPGGqQ",
  authDomain: "credibreak-412c0.firebaseapp.com",
  databaseURL: "https://credibreak-412c0.firebaseio.com",
  projectId: "credibreak-412c0",
  storageBucket: "credibreak-412c0.appspot.com",
  messagingSenderId: "300841218462",
  appId: "1:300841218462:web:d1a2e8e2e7e80e3c34268f",
  measurementId: "G-S0SPR0YZJ6",
};
firebase.initializeApp(firebaseConfig);

export default firebase;
