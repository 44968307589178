import React, { useState, useEffect } from "react";
import { LinkTable } from "./linkTable";
import { CreditLink } from "./../register/CreditLink";
import { GridContainer, GridItem } from "./../../../components/Grid/Grid";
import {
  Card,
  Chip,
  Container,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { LinkButton } from "./../../../components/Links/Links";
import { SimpleAccordion } from "src/components/Containers/SimpleAccordion";
import { LoanController } from "./../../../utils/controller/LoanController";
import { Money, Percent } from "./../../../components/Formats/FormatNumbers";
import { CustomerVoucherList, VoucherModal } from "./VoucherView";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { ConditionalWall } from "src/components/FilterWall/ConditionalWall";
import { SimplePaymentTable } from "../views/Payments";
import { numberPadStart } from "./../../../core/helpers";
export function LoanView() {
  const [updatekey, setKey] = useState(0);
  return (
    <Container maxWidth="xl">
      <GridContainer>
        <GridItem>
          <LinkButton href="/app/credit">Creditos</LinkButton>
        </GridItem>
        <GridItem md={4}>
          <CreditLink
            key={"no deberia-" + updatekey}
            onSave={() => {
              setKey(updatekey + 1);
            }}
          />
        </GridItem>
        <GridItem md={8}>
          <LinkTable key={updatekey} />
        </GridItem>
      </GridContainer>
    </Container>
  );
}
export function LoanList({ infoID }) {
  const [loans, setLoans] = useState([]);
  const loadLoans = () => {
    const fetch = new LoanController();
    fetch
      .get({
        infoId: infoID,
        needed: ["id", "creditName", "factor", "amount", "name"],
      })
      .then((response) => {
        setLoans(response.data);
      });
  };
  useEffect(loadLoans, [infoID]);

  return (
    <>
      {loans.map((item, key) => {
        return <LoanItem {...item} key={key} />;
      })}
    </>
  );
}
export function LoanItem(item) {
  const [percent, setPercent] = useState(0);
  return (
    <PanelContainer
      className="loanTable"
      title={`${item.creditCreditName} `}
      subtitle={
        <>
          <Chip
            label={item.creditStatusName}
            className={item.creditStatusName}
          />
          <ConditionalWall condition={item.creditStatusName !== "pendiente"}>
            <VoucherModal id={item.clientCreditId} />
          </ConditionalWall>
          <strong style={{ float: "right" }}>
            Folio:{numberPadStart(3, item.clientCreditId)}
          </strong>
        </>
      }>
      <GridItem s={6}>
        <Typography variant="h4">
          Prestamo:
          <Money
            number={item.clientCreditAmount}
            style={{ color: "darkgreen" }}
          />
        </Typography>
      </GridItem>
      <GridItem s={6}>
        <Typography variant="h4">
          Total:
          <Money
            number={item.creditFactor * item.clientCreditAmount}
            style={{ color: "darkgreen" }}
          />
        </Typography>
      </GridItem>
      <GridItem>
        <Card style={{ margin: "10px", padding: "15px" }}>
          <Percent
            number={isNaN(percent) ? 0 : percent}
            style={{ color: `hsl(${percent * 120}, 100%, 55%)` }}
          />
          <LinearProgress value={percent * 100} variant="determinate" />
        </Card>
      </GridItem>
      <GridItem>
        <ConditionalWall condition={item.creditStatusId === 2}>
          <SimpleAccordion
            title="Mis Comprobantes"
            style={{ display: "block" }}>
            <CustomerVoucherList loanID={item.clientCreditId} />
          </SimpleAccordion>
        </ConditionalWall>
      </GridItem>
      <GridItem>
        <SimplePaymentTable
          id={item.clientCreditId}
          onLoad={(content) => {
            let result = content.reduce(
              (acc, item) => {
                acc["total"] = (acc["total"] || item.rate) + item.rate;
                acc["paid"] = (acc["paid"] || 0) + item.actualPayment;
                if (item.status === "expirado") {
                  acc["total"] += item.surcharge;
                }
                return acc;
              },
              { total: 0, paid: 0 },
            );
            console.log(result.paid / result.total);
            setPercent(result.paid / result.total);
          }}
        />
      </GridItem>
    </PanelContainer>
  );
}
