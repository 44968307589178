import React, { useState, useEffect } from 'react';
import { ProfileWall } from 'src/localComponents/ProfileWall';
import DashboardView from 'src/views/reports/DashboardView';
import { ConditionalWall } from 'src/components/FilterWall/ConditionalWall';
import { UsersController } from 'src/utils/controller/UsersController';
import { CustomerView } from '../customer/CustumerView';
import { InfoController } from '../../utils/controller/InfoController';
import { AgentView } from '../middleman/view/MiddlemenView';

export function Dashboard() {
  return (
    <>
      <ProfileWall expectedProfiles={[ 1 ]}>
        <DashboardView />
      </ProfileWall>
      <ProfileWall expectedProfiles={[ 2 ]}>
        <ClientDashboard />
      </ProfileWall>
      <ProfileWall expectedProfiles={[ 3 ]}>
        <AgentDashboard />
      </ProfileWall>
    </>
  );
}
function ClientDashboard() {
  const [ clientID, setClientID ] = useState(0);
  const loadUserInfo = () => {
    const me = new InfoController().me();
    me.then((id) => {
      setClientID(id);
    });
  };
  useEffect(loadUserInfo, []);
  return (
    <ConditionalWall condition={Boolean(clientID)}>
      <CustomerView id={clientID} />
    </ConditionalWall>
  );
}

function AgentDashboard() {
  const me = new UsersController().me();
  return <AgentView id={me.id} />;
}
