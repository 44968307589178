import React, { useEffect, useState } from "react";
import { CreditLinkModel } from "./../../../utils/models/CreditLinkModel";
import Page from "./../../../components/Page";
import { PanelContainer } from "./../../../components/Containers/PanelContainer";
import {
  FormikSpace,
  SmartFormik,
} from "src/components/Containers/SmartFormik";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { InfoSelector } from "src/views/customer/components/InfoSelector";
import { CreditSelector } from "./../components/CreditSelector";
import { SimpleInput, SimpleSelector } from "src/components/Inputs/SimpleInput";
import { CreditController } from "./../../../utils/controller/CreditController";
import { optionalFn } from "src/core/helpers";
import { InfoController } from "./../../../utils/controller/InfoController";
import { useCState, useHistory } from "./../../../utils/hooks/simpleHooks";
import { GridContainer, GridItem } from "src/components/Grid/Grid";
import { Money } from "./../../../components/Formats/FormatNumbers";
import { FaIcon } from "src/components/Icons/FontIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
export function CreditLink ({ onSave }) {
  const classes = useStyles();
  const model = new CreditLinkModel();

  const [loading, setLoading] = useState(0);
  return (
    <Page title={"Asignar Credito"} className={classes.root}>
      <PanelContainer maxWidth="lg" title={"Asignar Credito"}>
        <SmartFormik
          model={model}
          onSubmit={(ev, { resetForm }) => {
            ev.creditStatusID = 1;
            ev.middlemanPaymentStatus = "pendiente";
            const fetch = new CreditController();
            setLoading(1);
            fetch.link(ev).then((response) => {
              optionalFn(onSave)(response);
            });
            resetForm({});
            return true;
          }}>
          {(mods) => (
            <FormikSpace>
              <InfoSelector
                name="infoID"
                title="Cliente"
                onChange={(ev, value) => {
                  if (!value) {
                    return mods.setFieldValue("infoID", "");
                  }
                  mods.setFieldValue("infoID", value.id);
                }}
              />
              <CreditSelector
                name="creditID"
                title="Credito"
                onChange={(ev, value) => {
                  if (!value) {
                    return mods.setFieldValue("creditID", "");
                  }
                  mods.setFieldValue("creditID", value.id);
                  mods.setFieldValue("factor", value.factor);
                  mods.setFieldValue("periodInDays", value.timePeriodInDays);
                }}
              />
              <SimpleSelector
                name="periodType"
                title="Periodo"
                onChange={(ev, value) => {
                  if (!value) {
                    return mods.setFieldValue("periodType", "");
                  }
                  mods.setFieldValue("periodType", value.value);
                }}
                options={[
                  { title: "Semanal", value: "weekly" },
                  { title: "Quincenal", value: "biweekly" },
                  { title: "Mensual", value: "monthly" },
                  { title: "Revolvente", value: "revolving" },
                ]}
              />

              <Box p={2}>
                <div style={{ width: "100%" }}>
                  <LoanSimulator
                    amount={mods.values.amount}
                    factor={mods.values.factor}
                    periodType={mods.values.periodType}
                    periodInDays={mods.values.periodInDays}
                  />
                </div>
                <Button
                  disabled={Boolean(loading)}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth>
                  Guardar
                </Button>
              </Box>
            </FormikSpace>
          )}
        </SmartFormik>
      </PanelContainer>
    </Page>
  );
}

export function CustomerLoanRequest () {
  const history = useHistory();
  const [loading, setLoading] = useState(0);
  const [infoID, setId] = useState(0);
  const [state, setState] = useCState({
    creditId: 0,
    periodType: "weekly",
    amount: 0,
  });
  const loadMyID = () => {
    const info = new InfoController();
    info.me().then((response) => {
      setId(response);
    });
  };
  useEffect(loadMyID, []);
  return (
    <Page title={"Solicitar Credito"}>
      <PanelContainer maxWidth="lg" title={"Solicitar Credito"}>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            let values = state;
            values.creditStatusID = 1;
            values.middlemanPaymentStatus = "pendiente";
            values.infoID = infoID;
            const fetch = new CreditController();
            setLoading(1);
            fetch.link(values).then((response) => {
              if (response.code === 200) {
                history("/");
              }
            });
            return true;
          }}>
          <CreditSelector
            name="creditID"
            title="Credito"
            onChange={(ev, value) => {
              if (!value) {
                return setState({ creditID: "" });
              }

              setState({
                creditID: value.id,
                factor: value.factor,
                periodInDays: value.timePeriodInDays,
              });
            }}
          />
          <SimpleInput
            type="number"
            name="amount"
            title="Dinero Solicitado"
            onChange={({ target }) => {
              const value = target.value;
              if (!value) {
                return setState({ amount: "" });
              }
              setState({ amount: value });
            }}
          />
          <SimpleSelector
            name="periodType"
            title="Periodo"
            onChange={(ev, value) => {
              if (!value) {
                return setState({ periodType: "" });
              }
              setState({ periodType: value.value });
            }}
            options={[
              { title: "Semanal", value: "weekly" },
              { title: "Quincenal", value: "biweekly" },
              { title: "Mensual", value: "monthly" },
              { title: "Revolvente", value: "revolving" },
            ]}
          />
          <LoanSimulator {...state} />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              disabled={Boolean(loading)}
              type="submit"
              color="primary"
              variant="contained">
              Guardar
            </Button>
          </Box>
        </form>
      </PanelContainer>
    </Page>
  );
}
export function LoanSimulator ({
  amount = 0,
  factor = 0,
  periodType = 1,
  periodInDays = 1,
}) {
  const total = amount * factor;
  let frag = 1;
  switch (periodType) {
    case "weekly":
      frag = 7;
      break;
    case "biweekly":
      frag = 15;
      break;
    default:
      frag = periodInDays;
      break;
  }
  frag = periodInDays / frag;

  return (
    <GridContainer>
      <GridItem lg={6}>
        <ResultCard title={"Total a pagar:"} gross={total} />
      </GridItem>
      <GridItem lg={6}>
        <ResultCard title={"Parcialidad aprox:"} gross={total / frag} />
      </GridItem>
    </GridContainer>
  );
}
function ResultCard ({ title, gross, ...rest }) {
  return (
    <Card {...rest}>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              <Money number={gross} />
            </Typography>
          </Grid>
          <Grid item>
            <Avatar>
              <FaIcon icon="dollar-sign" />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
