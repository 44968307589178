import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { UsersController } from "./../../utils/controller/UsersController";
import { SVG } from "src/components/Images/svgIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Debe ser un mail valido")
                .max(255)
                .required("Correo es requerido"),
              password: Yup.string()
                .max(255)
                .required("contraseña es requerida"),
            })}
            onSubmit={(data, { setSubmitting }) => {
              const fetch = new UsersController();
              fetch.login(data).then((response) => {
                if (response.code === 200) {
                  window.location.href = "/app/dashboard";
                }
                setSubmitting(false);
              });
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <SVG src="static/logo2.svg" />
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2">
                    Acceso al panel administrativo
                  </Typography>
                </Box>
                <Box mt={3} mb={1}></Box>
                <TextField
                  error={Boolean(errors.email)}
                  fullWidth
                  helperText={errors.email}
                  label="Correo Electronico"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Acceder
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
