import { Ajax } from "../core/ajax";
import { DOM } from "../core/dom";
import imageCompression from "browser-image-compression";
export function insteadIMG(path) {
  let img = path !== "" ? path : "https://picsum.photos/200/300";
  return img;
}
/**
 * Convierte cualquier arreglo de objetos en un archivo csv
 * @param [{*}] args
 */
export function arrayToCsv(args, titles = null, filename) {
  let csv = [];
  if (titles) {
    csv.push(join(titles, ",", []));
  }
  for (let items of args) {
    const values = Object.values(items);
    csv.push(join(values, ",", []));
  }
  let csvContent = "data:text/csv;charset=utf-8," + csv.join("\n");
  var encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename + ".csv");
  document.body.appendChild(link); // Required for FF
  link.click();
}
/**
 * turn array into string
 * @param [] args
 * @param string separator
 * @param [int] skip
 */
export function join(args, separator = ",", skip = []) {
  args = args.filter((item, key) => {
    return !skip.includes(key);
  });
  return args.join(separator);
}
export function printURL(path) {
  new DOM();

  path = envPath(path);
  new Ajax().fetchHTML(path).then((content) => {
    let html = document.createElement("div");
    html.innerHTML = content;
    html.print();
  });
}
export function envPath(path) {
  const url =
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_LOCAL_URL
      : process.env.REACT_APP_API_URL;
  return `${url}/${path}`;
}
export async function compress(images) {
  const options = {
    maxSizeMB: 0.6,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  let image = images.imagen;
  let img = false;
  try {
    img = await imageCompression(image, options);
    img = new File([img], "f.jpg", {
      type: "image/jpg",
      lastModified: new Date(),
    });
  } catch (error) {
    console.log(error);
  }
  images.imagen = img;
  return images;
}

//https://github.com/conekta/conekta-node/issues/55#issuecomment-443846326

export class ConektaHelper {
  cardValues = {};
  validations = [];
  constructor() {
    window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_PUB_KEY);
  }
  set card(number) {
    window.Conekta.card.getBrand(number);
    this.validations.push(window.Conekta.card.validateNumber(number));
    this.cardValues.number = number;
  }
  set cvc(number) {
    this.validations.push(window.Conekta.card.validateCVC(number));
    this.cardValues.cvc = number;
  }
  set expireDate(date) {
    console.log(date);
    const [month, year] = date.split("/");
    let exp_month = month.trim();
    let exp_year = year.trim();
    this.validations.push(
      window.Conekta.card.validateExpirationDate(exp_month, `20${exp_year}`),
    );
    this.cardValues = { ...this.cardValues, exp_month, exp_year };
  }
  set cardHolder(name) {
    this.cardValues.name = name;
  }
  tokenize(load, fail) {
    console.log(this.cardValues);

    const tokenParams = {
      card: this.cardValues,
    };
    return window.Conekta.Token.create(tokenParams, load, fail);
  }
}
