import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Box, Button, TextField } from "@material-ui/core";
import * as Yup from "yup";
import { optionalFn } from "src/core/helpers";
import {
  SimpleButton,
  SimpleInput,
} from "./../../../components/Inputs/SimpleInput";
import {
  FormikSpace,
  SmartFormik,
} from "src/components/Containers/SmartFormik";
import { InfoModel } from "./../../../utils/models/InfoModel";
import { ReferenceModel } from "./../../../utils/models/ReferenceModel";
import { GuarantorModel } from "./../../../utils/models/GuarantorModel";
import { UsersController } from "src/utils/controller/UsersController";
import { GridContainer, GridItem } from "src/components/Grid/Grid";
import { SimpleFileInput } from "src/components/Inputs/SimpleInput";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { isObjectEmpty } from "./../../../core/helpers";
import { InfoController } from "./../../../utils/controller/InfoController";
import swal from "sweetalert";
import { ConditionalWall } from "src/components/FilterWall/ConditionalWall";

export function SimpleUserRegister({ onSubmit, label = "Siguiente" }) {
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        name: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email es requerido"),
        password: Yup.string()
          .min(6)
          .max(255)
          .required("password es requerido"),
        name: Yup.string().max(255).required("Nombre de usuario es requerido"),
      })}
      onSubmit={onSubmit}>
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <SimpleInput
            error={Boolean(touched.name && errors.name)}
            msg={errors.name}
            title="Nombre de usuario"
            name="name"
            onChange={handleChange}
            value={values.name}
          />
          <CheckMail
            title="Correo Electronico"
            name="email"
            errors={touched["email"] && errors["email"]}
            onChange={(email) => {
              setFieldValue("email", email);
            }}
            type="email"
            value={values.email}
          />
          <CheckPassword
            touched={touched}
            errors={errors}
            onChange={(password) => {
              setFieldValue("password", password);
            }}
          />

          <Box my={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              {label}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
export function CheckPassword({ touched, errors, onChange }) {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(0);
  useEffect(() => {
    if (password === password2) {
      setError(0);
      optionalFn(onChange)(password);
    } else {
      setError(1);
      optionalFn(onChange)("");
    }
    // eslint-disable-next-line
  }, [password, password2]);
  return (
    <>
      <TextField
        error={Boolean(touched.password && errors.password) || error === 1}
        fullWidth
        helperText={error ? "Las contraseñas no coinciden" : ""}
        label="Contraseña"
        margin="normal"
        name="password"
        type="password"
        onChange={({ target }) => {
          setPassword(target.value);
        }}
        value={password}
        variant="outlined"
      />
      <TextField
        error={Boolean(touched.password && errors.password) || error === 1}
        fullWidth
        helperText={error ? "Las contraseñas no coinciden" : ""}
        label="Confirma contraseña"
        margin="normal"
        name="confirmPassword"
        type="password"
        value={password2}
        onChange={({ target }) => {
          setPassword2(target.value);
        }}
        variant="outlined"
      />
    </>
  );
}
export function CheckMail({ onChange, errors, ...rest }) {
  const [mail, setMail] = useState("");
  const [error, setError] = useState("");
  const user = new UsersController();
  useEffect(() => {
    setError(errors);
  }, [errors]);
  return (
    <SimpleInput
      error={Boolean(error)}
      msg={error}
      {...rest}
      onChange={({ target }) => {
        setMail(target.value);
      }}
      value={mail}
      onBlur={(ev) => {
        let mail = ev.target.value;
        user.checkForMail(mail).then((response) => {
          if (response.code > 200) {
            setError("Este correo ya existe!");
          } else {
            setError("");
            optionalFn(onChange)(mail);
          }
        });
      }}
    />
  );
}
export function CheckCurp({ onChange, errors, ...rest }) {
  const [curp, setCurp] = useState("");
  const [error, setError] = useState("");
  const info = new InfoController();
  useEffect(() => {
    setError(errors);
  }, [errors]);
  return (
    <SimpleInput
      error={Boolean(error)}
      msg={error}
      {...rest}
      onChange={({ target }) => {
        setCurp(target.value);
      }}
      value={curp}
      onBlur={(ev) => {
        let curp = ev.target.value;
        info.checkForCurp(curp).then((response) => {
          if (response) {
            setError("Este curp ya existe!");
            swal("Opps", "Parece que este curp ya existe", "error");
          } else {
            setError("");
            optionalFn(onChange)(curp);
          }
        });
      }}
    />
  );
}
export function InfoForm({ onSubmit }) {
  const model = new InfoModel();
  return (
    <>
      <SmartFormik model={model} onSubmit={onSubmit}>
        {({
          errors,
          handleChange,
          isSubmitting,
          values,
          touched,
          setFieldValue,
        }) => (
          <FormikSpace>
            <SimpleInput
              error={Boolean(errors["email"])}
              msg={errors["email"]}
              title="Correo electronico"
              name="email"
              value={values.email}
              onChange={handleChange}
            />
            <CheckCurp
              name="curp"
              title="CURP"
              errors={touched["curp"] && errors["curp"]}
              value={values.curp}
              onChange={(curp) => {
                setFieldValue("curp", curp);
              }}
            />
            <SimpleButton disabled={isSubmitting}>Siguiente</SimpleButton>
          </FormikSpace>
        )}
      </SmartFormik>
    </>
  );
}
export function ReferencesForm({ onSubmit }) {
  const model = new ReferenceModel();
  return (
    <>
      <SmartFormik model={model} onSubmit={onSubmit}>
        {(mods) => (
          <FormikSpace>
            <SimpleButton disabled={mods.isSubmitting}>Siguiente</SimpleButton>
          </FormikSpace>
        )}
      </SmartFormik>
    </>
  );
}
export function GuarantorForm({ onSubmit }) {
  const model = new GuarantorModel();
  return (
    <>
      <SmartFormik model={model} onSubmit={onSubmit}>
        {(mods) => (
          <FormikSpace>
            <SimpleButton disabled={mods.isSubmitting}>Siguiente</SimpleButton>
          </FormikSpace>
        )}
      </SmartFormik>
    </>
  );
}
export function VoucherForm({ onSubmit, optionalEdo = true }) {
  const [files, setFiles] = useCState({
    ine: null,
    reverso: null,
    address: null,
  });
  const [error, setErrors] = useState({});
  return (
    <GridContainer>
      <GridItem md={6}>
        <SimpleFileInput
          accept="image/jpeg"
          msg={"archivo requerido"}
          onChange={(files) => {
            setFiles({ ine: files[0] });
          }}
          title="INE"
          name="ine"
          error={Boolean(error["ine"])}
        />
      </GridItem>
      <GridItem md={6}>
        <SimpleFileInput
          accept="image/jpeg"
          msg={"archivo requerido"}
          onChange={(files) => {
            setFiles({ reverso: files[0] });
          }}
          title="Reverso de Ine"
          name="reverso"
          error={Boolean(error["reverso"])}
        />
      </GridItem>
      <GridItem md={6}>
        <SimpleFileInput
          accept="image/jpeg"
          msg={"archivo requerido"}
          onChange={(files) => {
            setFiles({ address: files[0] });
          }}
          title="Comprobante domi."
          name="address"
          error={Boolean(error["address"])}
        />
      </GridItem>
      <ConditionalWall condition={optionalEdo}>
        <GridItem md={6}>
          <SimpleFileInput
            accept="image/jpeg"
            msg={"archivo requerido"}
            onChange={(files) => {
              setFiles({ account: files[0] });
            }}
            title="Edo de cuenta"
            name="account"
            error={Boolean(error["account"])}
          />
        </GridItem>
      </ConditionalWall>
      <GridItem>
        Tus archivos deben ser de tipo jpg.
        <SimpleButton
          onClick={() => {
            const err = {};
            for (let key in files) {
              const file = files[key];
              if (!file) {
                err[key] = 1;
              }
            }
            setErrors(err);
            if (isObjectEmpty(err)) optionalFn(onSubmit)(files);
          }}>
          Siguiente
        </SimpleButton>
      </GridItem>
    </GridContainer>
  );
}
