import React from "react";
import { ConditionalWall } from "src/components/FilterWall/ConditionalWall";
import { LoginManager } from "./../utils/LoginManager";
export function ProfileWall({ expectedProfiles, children }) {
  const lm = new LoginManager();
  return (
    <ConditionalWall condition={lm.hasPermission(expectedProfiles)}>
      {lm.hasPermission(expectedProfiles)}
      {children}
    </ConditionalWall>
  );
}
