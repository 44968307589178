import React, { useEffect, useState } from "react";
import { FaIcon } from "src/components/Icons/FontIcon";
import { AsyncTable } from "./../../../components/Tables/Table";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { Box, Button, Chip, Container, Modal } from "@material-ui/core";
import {
  FormikSpace,
  SmartFormik,
} from "src/components/Containers/SmartFormik";
import {
  SimpleButton,
  SimpleInput,
  SimpleSelector,
} from "src/components/Inputs/SimpleInput";
import { envURL, optionalFn } from "src/core/helpers";
import { ImageController } from "./../../../utils/controller/ImageController";
import { SimpleFileInput } from "./../../../components/Inputs/SimpleInput";
import { ImageBackdrop } from "./../../../components/Images/Image";
import { VoucherModel } from "./../../../utils/models/VoucherModel";
import { VoucherController } from "src/utils/controller/VoucherController";
import { Money } from "./../../../components/Formats/FormatNumbers";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { ConditionalWall } from "src/components/FilterWall/ConditionalWall";
import { compress } from "src/utils/Utils";

export function CustomerVoucherList({ loanID, onSubmit, onStatusClick }) {
  return (
    <SimpleVoucherTable
      loanID={loanID}
      onStatusClick={onStatusClick}></SimpleVoucherTable>
  );
}
export function CustomerVoucherForm({
  loanID = 1,
  onSubmit,
  status = "pendiente",
}) {
  const model = new VoucherModel();
  const [loading, setLoading] = useState(0);
  return (
    <SmartFormik
      model={model}
      onSubmit={(ev) => {
        const fetch = new VoucherController();
        const sendImage = new ImageController();
        ev.status = status;
        ev.clientCreditID = loanID;
        setLoading(1);
        fetch.post(ev).then((response) => {
          const fkID = response.data.id;
          sendImage
            .post({ fkID, type: "voucher", voucher: ev.voucher })
            .then((response) => {
              setLoading(0);
            });
          optionalFn(onSubmit)({ ...ev, id: fkID });
        });
      }}>
      {(mods) => (
        <FormikSpace>
          <SimpleInput
            title="Fecha de pago"
            name="paymentDate"
            type="date"
            value={mods.values.paymentDate}
            onChange={({ target }) => {
              mods.setFieldValue("paymentDate", target.value);
            }}
          />
          <SimpleFileInput
            accept="image/jpeg"
            title="Voucher"
            name="voucher"
            onChange={(file) => {
              compress(file).then((resp) => {
                mods.setFieldValue("voucher", resp[0]);
              });
            }}
          />
          <SimpleButton disabled={Boolean(loading)}>
            {loading ? "Cargando..." : "Enviar"}
          </SimpleButton>
        </FormikSpace>
      )}
    </SmartFormik>
  );
}
export function VoucherModal({ id, onSubmit, status = "pendiente" }) {
  const [open, toggle] = useState(0);
  return (
    <Box display="flex" justifyContent="flex-end" p={2}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          toggle(1);
        }}>
        Enviar Pago
      </Button>
      <Modal
        open={Boolean(open)}
        onClose={() => {
          toggle(0);
        }}>
        <div>
          <PanelContainer title="Registro de voucher" maxWidth="sm">
            <CustomerVoucherForm
              status={status}
              loanID={id}
              onSubmit={(content) => {
                toggle(0);
                optionalFn(onSubmit)(content);
              }}
            />
          </PanelContainer>
        </div>
      </Modal>
    </Box>
  );
}
export function SimpleVoucherTable({ loanID, children, onStatusClick }) {
  const vouchers = new VoucherController();
  return (
    <Container maxWidth="xl" className="loanTable">
      <AsyncTable
        filler={() => {
          return vouchers.get({ clientCreditID: loanID });
        }}
        titles={{
          payment: "Pagos",
          paymentDate: "Fecha de pago",
          status: "Estado",
          action: { title: "Acciones", sort: false },
        }}
        format={(item) => {
          if (item.status === "cancelado") {
            return [];
          }
          const url = (envURL() + `/images/voucher-${item.id}`).replace(
            /api\//,
            "",
          );
          return [
            <Money number={item.payment} />,
            item.paymentDate,
            <Chip
              label={item.status}
              className={item.status}
              onClick={() => {
                optionalFn(onStatusClick)(item);
              }}
            />,
            <ImageBackdrop src={`${url}/voucher.jpg`}>
              <FaIcon icon="image" style={{ cursor: "pointer" }} />
            </ImageBackdrop>,
          ];
        }}
      />
      {children}
    </Container>
  );
}
export function ValidateVoucherForm({ id, expand, onSubmit }) {
  const [open, toggle] = useState(expand);
  const [resp, setResp] = useState(0);
  const [state, setState] = useCState({ response: "", status: "" });

  const loadData = () => {
    toggle(expand);
  };
  useEffect(loadData, [expand]);
  return (
    <Modal
      open={Boolean(open)}
      onClose={() => {
        toggle(0);
      }}>
      <>
        <PanelContainer title="Registro de voucher" maxWidth="sm">
          <form
            onSubmit={(ev) => {
              ev.preventDefault();
              toggle(0);
              if (!state.status.title) {
                return false;
              }
              const fetch = new VoucherController();

              fetch
                .put(id, {
                  response: state.response,
                  status: state.status.title,
                })
                .then((response) => {
                  optionalFn(onSubmit)(ev, response.data);
                });
            }}>
            <SimpleSelector
              title="Estado"
              value={state.status}
              options={[{ title: "pagado" }, { title: "cancelado" }]}
              onChange={(ev, values) => {
                setState({ status: values });
                if (values) setResp(values.title === "cancelado");
              }}
            />
            <ConditionalWall condition={resp}>
              <SimpleInput
                title="Razon"
                value={state.response}
                onChange={({ target }) => {
                  setState({ response: target.value });
                }}
              />
            </ConditionalWall>

            <SimpleButton>Enviar</SimpleButton>
          </form>
        </PanelContainer>
      </>
    </Modal>
  );
}
