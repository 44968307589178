import React, { useState } from "react";
import { FaIcon } from "src/components/Icons/FontIcon";
import { AsyncTable } from "./../../../components/Tables/Table";
import Page from "./../../../components/Page";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { SimpleSearchInput } from "./../../../components/Inputs/SimpleInput";
import { MiddlemanController } from "src/utils/controller/MiddlemanController";
import { LinkButton } from "./../../../components/Links/Links";
import { useHistory } from "./../../../utils/hooks/simpleHooks";
import { useLocation } from "react-router-dom";
import { Chip } from "@material-ui/core";
import "../../../components/Tables/scss/table.scss";
import { LoanController } from "./../../../utils/controller/LoanController";
import { Money } from "./../../../components/Formats/FormatNumbers";
import { LoginManager } from "./../../../utils/LoginManager";
export function MiddlemenTable() {
  const user = new MiddlemanController();
  const [search, setSearch] = useState("");
  const history = useHistory();
  return (
    <Page title="Comisionistas" style={{ marginTop: "20px" }}>
      <PanelContainer title="Comisionistas">
        <LinkButton href="/app/middleman/register">
          Registrar Comisionista
        </LinkButton>
        <SimpleSearchInput
          onChange={(value) => {
            setSearch(value);
          }}
        />
        <AsyncTable
          filler={user.get.bind(user)}
          needed={["id", "name", "surname"]}
          onRowClick={(content) => {
            history("/app/middleman", content);
          }}
          search={search}
          titles={{
            id: "ID",
            name: "Nombre",
            surname: "Apellido",
            action: { title: "Acciones", sort: false },
          }}
          format={({ id, name, surname }) => [
            id,
            name,
            surname,
            <FaIcon icon="user" />,
          ]}
        />
      </PanelContainer>
    </Page>
  );
}
export function AgentView({ id }) {
  const { state } = useLocation();
  const [search, setSearch] = useState("");
  id = id || state.id;
  const loadLoans = () => {
    const fetch = new LoanController();
    return fetch.get({
      clientCreditMiddlemanID: id,
      needed: ["amount", "id", "middlemanPaymentStatus", "name", "creditName"],
      perPage: 10,
    });
  };
  return (
    <Page title="Clientes" style={{ marginTop: "20px" }}>
      <PanelContainer title="Mis Clientes" className="loanTable">
        <SimpleSearchInput
          onChange={(value) => {
            setSearch(value);
          }}
        />
        <AsyncTable
          filler={loadLoans}
          search={search}
          titles={{
            infoName: "Nombre",
            creditCreditName: "Credito",
            clientCreditAmount: "Prestamo",
            creditStatusName: "Estado",
          }}
          format={({
            infoName,
            creditCreditName,
            clientCreditAmount,
            clientCreditMiddlemanPaymentStatus,
            clientCreditId,
          }) => [
            infoName,
            creditCreditName,
            <Money number={clientCreditAmount} />,
            <AgentPayment
              status={clientCreditMiddlemanPaymentStatus}
              id={clientCreditId}
            />,
          ]}
        />
      </PanelContainer>
    </Page>
  );
}
function AgentPayment({ status, id }) {
  const [middlemanPaymentStatus, setStatus] = useState(status);
  return (
    <Chip
      label={middlemanPaymentStatus}
      className={middlemanPaymentStatus}
      onClick={() => {
        if (middlemanPaymentStatus === "pagado") return false;
        const lm = new LoginManager();
        if (!lm.hasPermission([1])) {
          return false;
        }
        const fetch = new LoanController();
        setStatus("pagado");
        fetch.put(id, { middlemanPaymentStatus: "pagado" });
      }}
    />
  );
}
