import React, { useEffect, useState } from 'react';
import { PanelContainer } from 'src/components/Containers/PanelContainer';
import { useNavigate } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { GridContainer, GridItem } from 'src/components/Grid/Grid';
import { AsyncTable } from '../../../components/Tables/Table';
import Page from '../../../components/Page';
import { SimpleSearchInput } from '../../../components/Inputs/SimpleInput';
import { CreditController } from '../../../utils/controller/CreditController';
import { CreditStatusSelector } from '../components/CreditSelector';
import './scss/loan.scss';
import { Money } from '../../../components/Formats/FormatNumbers';
import { LoginManager } from '../../../utils/LoginManager';
import { numberPadStart } from '../../../core/helpers';
import { useHistory } from '../../../utils/hooks/simpleHooks';

export function LinkTable({ style, extra }) {
  const link = new CreditController();
  const [search, setSearch] = useState('');
  const [creditStatus, setStatus] = useState();
  const history = useNavigate();
  return (
    <Page
      title="Prestamos"
      style={{ marginTop: '20px', ...style }}
      className="loanTable"
    >
      <PanelContainer title="Prestamos" maxWidth={false}>
        <SimpleSearchInput
          onChange={value => {
            setSearch(value);
          }}
        />
        <CreditStatusSelector
          onChange={(ev, val) => {
            const id = val ? val.id : null;
            setStatus(id);
          }}
        />
        <AsyncTable
          onRowClick={content => {
            const lm = new LoginManager();
            if (!lm.hasPermission([1])) {
              return false;
            }
            history(`/app/client/loan/${content.clientCreditId}`, {
              // replace: true,
              state: content
            });
          }}
          key={creditStatus}
          filler={val => {
            const values = creditStatus
              ? {
                orderBy: 'clientCreditId',
                orderType: 'DESC',
                creditStatusId: creditStatus,
                ...val,
                ...extra
              }
              : {
                orderBy: 'clientCreditId',
                orderType: 'DESC',
                ...val,
                ...extra
              };
            return link.getLinks(values);
          }}
          needed={[
            'id',
            'name',
            'surname',
            'amount',
            'periodType',
            'status',
            'creditName',
            'creditStatusID',
            'factor',
            'updated_at'
          ]}
          search={search}
          titles={{
            clientCreditId: 'Folio',
            infoName: 'Nombre',
            creditCreditName: 'Credito',
            creditFactor: 'Factor',
            clientCreditAmount: 'Prestamo',
            clientCreditUpdated_at: 'Fecha',
            clientCreditPeriodType: 'Periodo',
            creditStatusName: 'Estado'
          }}
          format={({
            clientCreditId,
            infoName,
            creditCreditName,
            creditFactor,
            clientCreditAmount,
            clientCreditPeriodType,
            creditStatusName,
            clientCreditUpdated_at
          }) => [
              numberPadStart(3, clientCreditId),
              infoName,
              creditCreditName,
              creditFactor,
              <Money number={clientCreditAmount} />,
              clientCreditUpdated_at,
              clientCreditPeriodType,

              <Chip label={creditStatusName} className={creditStatusName} />
            ]}
        />
      </PanelContainer>
    </Page>
  );
}
export function CreditTable() {
  const link = new CreditController();
  const [search, setSearch] = useState('');
  return (
    <Page title="Creditos" style={{ marginTop: '20px' }}>
      <PanelContainer title="Creditos">
        <SimpleSearchInput
          onChange={value => {
            setSearch(value);
          }}
        />
        <AsyncTable
          filler={link.get.bind(link)}
          needed={[
            'id',
            'timePeriodInDays',
            'creditType',
            'creditName',
            'factor'
          ]}
          search={search}
          titles={{
            id: 'ID',
            timePeriodInDays: 'Periodo',
            creditType: 'Tipo',
            creditName: 'Nombre',
            factor: '%'
          }}
          format={({
            id,
            timePeriodInDays,
            creditType,
            creditName,
            factor
          }) => [
              id,
              timePeriodInDays,
              creditType,
              creditName,
              `${((factor - 1) * 100).toPrecision(2)}%`
            ]}
        />
      </PanelContainer>
    </Page>
  );
}
export function ResumeLoanList() {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(2);
  const [data, setData] = useState([]);
  const loadLinks = () => {
    const fetch = new CreditController();
    fetch
      .getLinks({
        search,
        creditStatusId: status,
        needed: [
          'id',
          'amount',
          'name',
          'name',
          'timePeriodInDays',
          'factor',
          'creditName'
        ]
      })
      .then(resp => {
        setData(resp.data);
      });
  };
  useEffect(loadLinks, [search, status]);
  return (
    <>
      <SimpleSearchInput
        onChange={value => {
          setSearch(value);
        }}
      />
      <CreditStatusSelector
        onChange={(ev, val) => {
          const id = val ? val.id : null;
          setStatus(id);
        }}
      />
      {data.map(item => (
        <div
          key={item.clientCreditId}
          onClick={() => {
            history(`/app/client/loan/${item.clientCreditId}`);
          }}
        >
          <GridContainer
            style={{
              boxShadow: '1px 1px 1px #eee',
              margin: '8px',
              padding: '2px',
              borderRadius: '9px',
              textAlign: 'center'
            }}
          >
            <GridItem xs={6} style={{ textAlign: 'center', margin: '10px' }}>
              <h5>{item.creditCreditName}</h5>
            </GridItem>
            <GridItem xs={6} style={{ textAlign: 'center', margin: '10px' }}>
              <h5>{item.infoName}</h5>
            </GridItem>
            <GridItem xs={6} style={{ background: '#efefef', margin: '3px' }}>
              Folio:
              {numberPadStart(2, item.clientCreditId)}
            </GridItem>
            <GridItem xs={6} style={{ background: '#efefef', margin: '3px' }}>
              <Money
                number={item.creditFactor * item.clientCreditAmount}
                style={{ color: 'green' }}
              />
            </GridItem>
          </GridContainer>
        </div>
      ))}
    </>
  );
}
