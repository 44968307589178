import { BaseModel } from "./BaseModel";
import * as Yup from "yup";
import { validInputDate } from "src/core/helpers";
export class VoucherModel extends BaseModel {
  values = {
    payment: {
      title: "Pago",
      validation: Yup.number()
        .min(1, "el pago tiene que ser un numero valido")
        .max(1000000, "El limite de pago por exibicion es de un millon")
        .required("Pago es requerido"),
      type: "number",
    },
    paymentDate: {
      title: "Fecha de pago",
      validation: Yup.date().required("fecha es requerido"),
      type: "date",
      value: validInputDate(),
    },
    voucher: {
      title: "Voucher",
    },
  };
}
