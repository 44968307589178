import React, { useState } from "react";
import { AsyncTable } from "src/components/Tables/Table";
import { PaymentController } from "./../../../utils/controller/PaymentController";
import { Money } from "./../../../components/Formats/FormatNumbers";
import { Chip, Tab, Tabs } from "@material-ui/core";
import "./scss/loan.scss";
import { FaIcon } from "src/components/Icons/FontIcon";
import Page from "./../../../components/Page";
import { PanelContainer } from "./../../../components/Containers/PanelContainer";
import { GridItem } from "src/components/Grid/Grid";
import {
  SimpleButton,
  SimpleInput,
  SimpleSearchInput,
} from "./../../../components/Inputs/SimpleInput";
import { validInputDate } from "src/core/helpers";
import { useHistory } from "./../../../utils/hooks/simpleHooks";
import { GridContainer } from "./../../../components/Grid/Grid";
import { ConditionalWall } from "./../../../components/FilterWall/ConditionalWall";
import { numberPadStart } from "./../../../core/helpers";

export function GlobalPayments() {
  const [tab, setTab] = useState(0);
  return (
    <Page title="Pagos esperados">
      <PanelContainer
        title="Pagos esperados"
        className="loanTable"
        maxWidth="xl">
        <Tabs
          value={tab}
          onChange={(ev, newValue) => {
            setTab(newValue);
          }}>
          <Tab label={"Pagos Semanales"} />
          <Tab label={"Pagos Expirados"} />
        </Tabs>
        <ConditionalWall condition={tab === 0}>
          <CurrentPayments />
        </ConditionalWall>
        <ConditionalWall condition={tab === 1}>
          <ExpiredPayments />
        </ConditionalWall>
      </PanelContainer>
    </Page>
  );
}
export function CurrentPayments() {
  let today = new Date();
  let nxtWeek = new Date().setDate(today.getDate() + 7);
  const [since, setInit] = useState(validInputDate(today.toString()));
  const [ky, setKey] = useState(0);
  const [search, setQuery] = useState("");
  const [until, setEnd] = useState(
    validInputDate(new Date(nxtWeek).toString()),
  );
  return (
    <GridContainer>
      <GridItem>
        <SimpleSearchInput
          onChange={(value) => {
            setQuery(value);
            setKey(ky + 1);
          }}
        />
      </GridItem>
      <GridItem s={6}>
        <SimpleInput
          type="date"
          title="Desde"
          value={since}
          onChange={({ target }) => {
            setInit(target.value);
          }}
        />
      </GridItem>
      <GridItem s={6}>
        <SimpleInput
          type="date"
          title="Hasta"
          value={until}
          onChange={({ target }) => {
            setEnd(target.value);
          }}
        />
      </GridItem>
      <GridItem>
        <SimpleButton
          onClick={() => {
            setKey(ky + 1);
          }}>
          Filtrar
        </SimpleButton>
      </GridItem>
      <GridItem>
        <SimpleGlobalPayments key={ky} extra={{ since, until, search }} />
      </GridItem>
    </GridContainer>
  );
}
export function ExpiredPayments() {
  const [search, setQuery] = useState("");
  return (
    <div className="loanTable">
      <SimpleSearchInput
        onChange={(value) => {
          setQuery(value);
        }}
      />
      <SimpleGlobalPayments
        key={search}
        extra={{ paymentsStatus: "expirado", search }}
      />
    </div>
  );
}
export function SimpleGlobalPayments({ extra }) {
  const pm = new PaymentController();
  const history = useHistory();
  return (
    <AsyncTable
      titles={{
        paymentsExpectedPaymentDate: "Fecha",
        infoName: "Cliente",
        clientCreditId: "Folio",
        creditCreditName: "Credito",
        paymentsStatus: "status",
        paymentsRate: "Cargo",
        paymentsSurcharge: "Recargo",
        action: { title: "Acciones", sort: false },
      }}
      needed={[
        "expectedPaymentDate",
        "creditName",
        "name",
        "status",
        "id",
        "rate",
        "surcharge",
      ]}
      format={(content) => [
        content.paymentsExpectedPaymentDate,
        content.infoName,
        numberPadStart(3, content.clientCreditId),
        content.creditCreditName,
        <Chip
          label={content.paymentsStatus}
          className={content.paymentsStatus}
          onClick={() => {
            history(`/app/client/loan/${content.clientCreditId}`);
          }}
        />,
        <Money number={content.paymentsRate} />,
        <Money number={content.paymentsSurcharge} />,
        <>
          <FaIcon
            icon="user"
            style={{ margin: "10px", color: "blue", cursor: "pointer" }}
            onClick={() => {
              history(`/app/customer/profile/${content.infoId}`);
            }}
          />
        </>,
      ]}
      filler={(val) => {
        return pm.get({ ...val, ...extra });
      }}
    />
  );
}
