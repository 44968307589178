import { BaseController } from './BaseController';
import { AuthFetch } from '../AuthFetch';

export class CreditController extends BaseController {
  path = 'credit';

  link(values) {
    const fetch = new AuthFetch('clients/credits');
    return fetch.post(values);
  }

  getLinks(values) {
    const fetch = new AuthFetch('clients/credits');
    return fetch.get(values);
  }

  getStatus(values) {
    const fetch = new AuthFetch('credits/status');
    return fetch.get(values);
  }
}
