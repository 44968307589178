import { BaseController } from "./BaseController";
import { AuthFetch } from "./../AuthFetch";
import { UsersController } from "src/utils/controller/UsersController";
export class InfoController extends BaseController {
  path = "info";
  async me() {
    const fetch = new AuthFetch(this.path);
    const me = new UsersController().me();
    let res = await fetch.get({ needed: ["id"], userID: me.id });
    return res.data[0].id;
  }
  async checkForCurp(curp) {
    let content = await super.get({ curp, needed: ["curp"] });
    return content.data.length > 0;
  }
}
