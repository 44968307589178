import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

import { numberToMoney } from "./../../../core/helpers";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Sales = ({ className, sales, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: formatDataset(sales),

    labels: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: true },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return numberToMoney(value);
            },
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.primary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.primary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
      callbacks: {
        label: (tooltipItem, chart) => {
          return numberToMoney(tooltipItem.yLabel);
        },
      },
    },
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Ingresos" />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
};
function formatDataset(data) {
  let colors = ["blue", "red"];
  if (!data) return [];
  data = Object.values(data)[0];
  let payments = Object.values(data).map((month) => month.payments);
  let loans = Object.values(data).map((month) => month.loans);
  payments = {
    backgroundColor: colors[0],
    data: payments,
    barThickness: "15",
    label: "Pagos",
  };
  loans = {
    backgroundColor: colors[1],
    data: loans,
    barThickness: "15",
    label: "Prestamos",
  };
  return [payments, loans];
}
export default Sales;
