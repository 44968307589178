import React, { useState } from "react";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { GridContainer, GridItem } from "src/components/Grid/Grid";
import { UsersController } from "src/utils/controller/UsersController";
import {
  SimpleButton,
  SimpleInput,
  SimpleSearchInput,
} from "./../../../components/Inputs/SimpleInput";
import Page from "src/components/Page";
import { AsyncTable } from "./../../../components/Tables/Table";
import { SimpleUserRegister } from "./../../customer/Register/simpleUserRegister";
import { FaIcon } from "src/components/Icons/FontIcon";
import { Button, Container, Modal } from "@material-ui/core";

import * as Yup from "yup";
import { Formik } from "formik";
import swal from "sweetalert";
export function UsersView() {
  const [key, setKey] = useState(0);
  return (
    <Container maxWidth="lg">
      <GridContainer>
        <GridItem md={4}>
          <PanelContainer title="Registro de Usuarios">
            <SimpleUserRegister
              key={"form-" + key}
              onSubmit={(ev) => {
                const fetch = new UsersController();
                ev.profileID = 1;
                fetch.post(ev).then(() => {
                  setKey(key + 1);
                });
              }}
              label={"Guardar"}
            />
          </PanelContainer>
        </GridItem>
        <GridItem md={8}>
          <UsersTable key={key} />
        </GridItem>
      </GridContainer>
    </Container>
  );
}
export function UsersTable() {
  const user = new UsersController();
  const [search, setSearch] = useState("");
  return (
    <Page title="Usuarios">
      <PanelContainer title="Usuarios">
        <SimpleSearchInput
          onChange={(value) => {
            setSearch(value);
          }}
        />
        <AsyncTable
          filler={user.get.bind(user)}
          needed={["id", "name", "profileName", "email"]}
          search={search}
          titles={{
            usersId: "ID",
            usersName: "Nombre de usuario",
            usersEmail: "Correo Electronico",
            profileProfileName: "perfil",
            action: { title: "Acciones", sort: false },
          }}
          format={({ usersId, usersName, usersEmail, profileProfileName }) => [
            usersId,
            usersName,
            usersEmail,
            profileProfileName,
            <PasswordMiniForm id={usersId} />,
          ]}
        />
      </PanelContainer>
    </Page>
  );
}
export function PasswordMiniForm({ id }) {
  const [open, toggle] = useState(0);
  return (
    <div>
      <Button
        onClick={() => {
          toggle(1);
        }}>
        <FaIcon icon="lock" />
      </Button>
      <Modal
        open={Boolean(open)}
        onClose={() => {
          toggle(0);
        }}>
        <>
          <PanelContainer title="Actualizar contraseña" maxWidth="sm">
            <Formik
              initialValues={{
                password: "",
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(6)
                  .max(255)
                  .required("password es requerido"),
              })}
              onSubmit={(ev, { resetForm }) => {
                toggle(0);
                resetForm({ password: "" });
                const fetch = new UsersController();
                fetch.put(id, ev).then((response) => {
                  swal(
                    "Cambio de contraseña",
                    "Actualizacion de contraseña completado",
                    "success",
                  );
                });
              }}>
              {({ handleSubmit, values, handleChange, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <GridItem>
                        <SimpleInput
                          error={Boolean(errors.password)}
                          msg={errors.password}
                          title="Contraseña"
                          type="password"
                          onChange={handleChange}
                          value={values.name}
                          name="password"
                        />
                      </GridItem>
                      <GridItem>
                        <SimpleButton>Guardar</SimpleButton>
                      </GridItem>
                    </GridContainer>
                  </form>
                );
              }}
            </Formik>
          </PanelContainer>
        </>
      </Modal>
    </div>
  );
}
