import React from "react";
import { SVG } from "./Images/svgIcons";
import "./Containers/scss/esComponents.scss";
const Logo = (props) => {
  return (
    <SVG
      alt="Logo"
      className="logo"
      src="/static/logo2.svg"
      {...props}
      style={{
        stroke: "white",
        fill: "white!important",
        width: "160px",
        borderRadius: "50%",
      }}
    />
  );
};

export default Logo;
