import React, { useEffect } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Budget from "./Budget";
import Sales from "./Sales";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { ReportsController } from "./../../../utils/controller/ReportsController";
import { NetCard, PaymentProgressCard, PendingLoanCard } from "./Reports";
import { LinkTable, ResumeLoanList } from "./../../credit/views/linkTable";
import { ScreenRangeContainer } from "src/components/FilterWall/ConditionalWall";
import { PanelContainer } from "./../../../components/Containers/PanelContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [reports, setReports] = useCState({ completed: { pagado: 0 } });
  const loadReports = () => {
    const fetch = new ReportsController();
    fetch.get().then((response) => {
      setReports(response.data);
    });
  };
  useEffect(loadReports, []);
  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              gross={reports.gross}
              lastWeekGross={reports.lastWeekGross}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <PendingLoanCard {...reports} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <PaymentProgressCard payment={reports.completed.pagado} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <NetCard net={reports.net} />
          </Grid>

          <Grid item xs={12}>
            <Sales sales={reports.sales} />
          </Grid>
          <Grid item xs={12}>
            <ScreenRangeContainer max={1024}>
              <PanelContainer>
                <ResumeLoanList />
              </PanelContainer>
            </ScreenRangeContainer>
            <ScreenRangeContainer min={1025}>
              <LinkTable style={{ margin: 0, padding: 0 }} />
            </ScreenRangeContainer>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
