import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { SimpleButton } from "src/components/Inputs/SimpleInput";
import { WizardForm, WizardPage } from "src/components/Wizard/WizardForm";
import { SplashScreen } from "./../../../components/Containers/SplashScreen";

import { UsersController } from "src/utils/controller/UsersController";

import { InfoController } from "./../../../utils/controller/InfoController";
import { ReferenceController } from "./../../../utils/controller/ReferenceController";
import { GuarantorController } from "./../../../utils/controller/GuarantorController";
import { ImageController } from "./../../../utils/controller/ImageController";
import { useNavigate } from "react-router-dom";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import {
  GuarantorForm,
  InfoForm,
  ReferencesForm,
  SimpleUserRegister,
  VoucherForm,
} from "./simpleUserRegister";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { LoginManager } from "./../../../utils/LoginManager";
export function CustomerWizard() {
  const [page, setPage] = useState(0);
  const [content, setContent] = useCState({});
  const [splashMsg, setMsg] = useState("");
  let navigate = useNavigate();
  return (
    <>
      <SplashScreen open={Boolean(splashMsg)}>{splashMsg}</SplashScreen>
      <PanelContainer>
        <WizardForm page={page} title="Registro de usuarios">
          <WizardPage title="Datos de usuario">
            <SimpleUserRegister
              onSubmit={(ev) => {
                setContent({ user: ev });
                setPage(1);
              }}
            />
          </WizardPage>
          <WizardPage title="Perfil">
            <InfoForm
              onSubmit={(ev) => {
                setContent({ info: ev });
                setPage(2);
              }}
            />
          </WizardPage>
          <WizardPage title="Comprobantes">
            <VoucherForm
              onSubmit={(files) => {
                setContent({ infoVouchers: files });
                setPage(3);
              }}
            />
          </WizardPage>
          <WizardPage title="Referencia">
            <ReferencesForm
              onSubmit={(ev) => {
                setContent({ ref1: ev });
                setPage(4);
              }}
            />
          </WizardPage>
          <WizardPage title="Referencia 2">
            <ReferencesForm
              onSubmit={(ev) => {
                setContent({ ref2: ev });
                setPage(5);
              }}
            />
          </WizardPage>
          <WizardPage title="Aval">
            <GuarantorForm
              onSubmit={(ev) => {
                setContent({ guarantor: ev });
                setPage(6);
              }}
            />
          </WizardPage>
          <WizardPage title="Comprobantes de Aval">
            <VoucherForm
              optionalEdo={false}
              onSubmit={(files) => {
                setContent({ guarantorVouchers: files });
                setPage(7);
              }}
            />
          </WizardPage>
          <WizardPage title="Finalizar Registro">
            <Typography variant="h3">
              Haz completado todos los pasos.
            </Typography>
            <Typography>Da click en finalizar para terminar</Typography>
            <SimpleButton
              style={{ marginTop: "3rem" }}
              onClick={(ev) => {
                save(content, setMsg).then(() => {
                  const lm = new LoginManager();
                  if (lm.isLogged()) {
                    return navigate("/app/customers");
                  }
                  navigate("/");
                });
              }}>
              Finalizar
            </SimpleButton>
          </WizardPage>
        </WizardForm>
      </PanelContainer>
    </>
  );
}
async function save(content, hook) {
  hook("Creando un nuevo Usuario...");
  const user = new UsersController();
  const info = new InfoController();
  const ref = new ReferenceController();
  const guarantorController = new GuarantorController();
  const img = new ImageController();
  content.user.profileID = 2;
  const userRes = await user.post(content.user);
  content.info.userID = userRes.user.id;

  hook("Almacenando informacion de contacto");
  const infoRes = await info.post(content.info);
  content.guarantor.infoID = infoRes.data.id;
  hook("Almacenando informacion aval");
  const guarantorRes = await guarantorController.post(content.guarantor);
  content.ref1.infoID = infoRes.data.id;
  content.ref2.infoID = infoRes.data.id;
  content.infoVouchers.fkID = infoRes.data.id;
  content.infoVouchers.type = "info";
  content.guarantorVouchers.fkID = guarantorRes.data.id;
  content.guarantorVouchers.type = "guarantor";

  hook("Cargando imagenes...");
  return Promise.all([
    ref.post(content.ref1),
    ref.post(content.ref2),
    img.post(content.infoVouchers),
    img.post(content.guarantorVouchers),
  ]);
}
