import React, { useEffect, useState } from "react";
import { SimpleSelector } from "src/components/Inputs/SimpleInput";
import { InfoController } from "./../../../utils/controller/InfoController";
export function InfoSelector({ ...rest }) {
  const [options, setOptions] = useState([]);
  const loadOptions = () => {
    const fetch = new InfoController();
    fetch
      .get({ needed: ["id", "name", "surname", "email"] })
      .then((response) => {
        setOptions(
          response.data.map((item) => ({
            title: `${item.name} ${item.surname} (${item.email})`,
            id: item.id,
          })),
        );
      });
  };
  useEffect(loadOptions, []);
  return <SimpleSelector title="Clientes" options={options} {...rest} />;
}
