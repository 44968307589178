import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/Money";
import { Money, Percent } from "./../../../components/Formats/FormatNumbers";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: "green",
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: "green",
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

const Budget = ({ className, gross, lastWeekGross, ...rest }) => {
  const classes = useStyles();
  let diff = (gross - lastWeekGross) / gross;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Ingreso bruto semanal
            </Typography>
            <Typography color="textPrimary" variant="h3">
              <Money number={gross} />
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography variant="body2">
            <Percent number={diff} />
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Desde el martes pasado.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
};

export default Budget;
