import { BaseModel } from "./BaseModel";
export class PurchaseModel extends BaseModel {
  values = {
    billing_name: { title: "Nombres", value: "" },

    billing_apellidoPaterno: { title: "Paterno", value: "" },
    billing_apellidoMaterno: { title: "Materno", value: "" },
    billing_rfc: { title: "RFC" },
    billing_email: { title: "Correo", value: "" },
    billing_phone: { value: "" },
    billing_state: { value: "" },
    billing_city: { value: "" },
    billing_company: { value: "" },
    billing_country: { value: "" },
    billing_postcode: { value: "" },
    billing_address_1: { value: "" },
    billing_address_2: { value: "" },

    "transaction_type-1": { title: "Dividir pago en semanas", value: "" },
  };
}
