import React from "react";
import { FaIcon } from "src/components/Icons/FontIcon";
import { Money, Percent } from "./../../../components/Formats/FormatNumbers";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  colors,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

export const ReportsCard = ({
  className,
  title,
  mainComponent,
  secondaryComponent,
  icon,
  color = "orange",
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {mainComponent}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: color }}>
              <FaIcon icon={icon} style={{ color: "white" }} />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          {secondaryComponent}
        </Box>
      </CardContent>
    </Card>
  );
};

export function PendingLoanCard({ needs, gross }) {
  return (
    <ReportsCard
      title={"Acomulado  Pendientes"}
      icon="money-bill"
      mainComponent={<Money number={needs} />}
      secondaryComponent={
        <>
          <Percent number={gross / needs} /> confirmado
        </>
      }
    />
  );
}
export function PaymentProgressCard({ payment = 0 }) {
  let color = Math.floor(payment * 120);
  return (
    <ReportsCard
      title={"Prestamos Cobrados"}
      icon="percent"
      color={`hsl(${color}, 100%, 55%)`}
      mainComponent={
        <Percent
          number={payment}
          style={{ color: `hsl(${color}, 100%, 55%)` }}
        />
      }
      secondaryComponent={
        <Box mt={3} style={{ width: "100%" }}>
          <LinearProgress value={payment * 100} variant="determinate" />
        </Box>
      }
    />
  );
}
export function NetCard({ net = 0 }) {
  return (
    <ReportsCard
      title={"Ganancias Netas"}
      icon="dollar-sign"
      color="green"
      mainComponent={<Money number={net} />}
      secondaryComponent={<></>}
    />
  );
}
