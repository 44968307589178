import { BaseModel } from "./BaseModel";
import * as Yup from "yup";
export class PaymentModel extends BaseModel {
  values = {
    payment: {
      title: "Cantidad a pagar",
      validation: Yup.number().min(0).required("Numero es requerido"),
    },
    folio: {
      title: "Folio",
    },
  };
}
