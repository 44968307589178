import { Card, Container, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GridContainer, GridItem } from "./../../../components/Grid/Grid";
import { useParams } from "react-router-dom";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import { Money } from "../../../components/Formats/FormatNumbers";
import { SimpleButton } from "src/components/Inputs/SimpleInput";
import {
  ConditionalWall,
  ScreenRangeContainer,
} from "src/components/FilterWall/ConditionalWall";
import { LoanController } from "./../../../utils/controller/LoanController";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { PaymentTable } from "./../../credit/views/Payments";
import { optionalFn, validInputDate } from "src/core/helpers";
import { CreditController } from "./../../../utils/controller/CreditController";
import { ClientListData } from "src/views/customer/CustumerView";

import { VoucherController } from "./../../../utils/controller/VoucherController";
import {
  ValidateVoucherForm,
  SimpleVoucherTable,
  VoucherModal,
} from "./../../credit/views/VoucherView";

import { CustomModal } from '../../../components/CustomModal/CustomModal';

export function ClientLoanView () {
  const { id } = useParams();
  console.log(id);
  const [state, setState] = useCState({ clientCreditId: id });
  const [key, setKey] = useState(id);
  const [openResponseForm, toggleModal] = useState(0);
  const [voucherID, setID] = useState(0);
  console.log(state);
  const loadLoan = () => {
    const fetch = new CreditController();
    fetch
      .getLinks({
        needed: [
          "id",
          "name",
          "surname",
          "amount",
          "periodType",
          "status",
          "creditName",
          "creditStatusID",
          "factor",
        ],
        clientCreditId: id,
      })
      .then((response) => {
        setState(response.data[0]);
      });
  };
  useEffect(loadLoan, [key, id]);
  return (
    <Container maxWidth="xl" key={id}>
      <GridContainer>
        <GridItem>
          <CreditBar
            key={"credit-" + key}
            id={state.clientCreditId}
            amount={state.clientCreditAmount}
            factor={state.creditFactor}
            name={state.creditCreditName}
            clientName={state.infoName}
            statusID={state.creditStatusId}
            statusName={state.creditStatusName}
            onClick={() => {
              setKey(key + 1);
            }}
          />
        </GridItem>
        <ScreenRangeContainer min={1200}>
          <GridItem md={4}>
            <ClientView
              id={state.infoId}
              loanID={state.clientCreditId}
              statusID={state.creditStatusId}
              onClick={() => {
                setKey(key + 1);
              }}
            />
          </GridItem>
        </ScreenRangeContainer>
        <GridItem md={8}>
          <ConditionalWall condition={[2, 4, 5].includes(state.creditStatusId)}>
            <PanelContainer title={"Vouchers"}>
              <VoucherModal
                id={state.clientCreditId}
                status="pagado"
                onSubmit={(content) => {
                  const fetch = new VoucherController();
                  return fetch
                    .put(content.id, {
                      status: content.status,
                    })
                    .then((response) => {
                      setKey(key + 1);
                    });
                }}
              />
              <SimpleVoucherTable
                key={"voucher-" + key}
                loanID={state.clientCreditId}
                onStatusClick={(clicked) => {
                  toggleModal(1);
                  setID(clicked.id);
                }}
              />
            </PanelContainer>

            <PaymentTable key={key} id={state.clientCreditId} />
          </ConditionalWall>
        </GridItem>
      </GridContainer>
      <ValidateVoucherForm
        expand={openResponseForm}
        id={voucherID}
        onSubmit={() => {
          toggleModal(0);
          setKey(key + 1);
          setID(0);
        }}
      />
    </Container>
  );
}
function CreditBar ({
  name,
  amount,
  factor,
  statusID,
  id,
  statusName,
  onClick,
  clientName,
}) {
  return (
    <PanelContainer
      title={`Detalles del credito de ${clientName}`}
      maxWidth="xl">
      <GridContainer>
        <GridItem s={12} md={3}>
          <Typography variant="h2">{name}</Typography>
        </GridItem>
        <GridItem s={6} md={4}>
          <Typography variant="h2">
            Prestamo:
            <Money number={amount} style={{ color: "darkgreen" }} />
          </Typography>
        </GridItem>
        <GridItem s={6} md={3}>
          <Typography variant="h2">
            Total:
            <Money number={factor * amount} style={{ color: "darkgreen" }} />
          </Typography>
        </GridItem>
        <GridItem s={12} md={2}>
          <LoadStatusButton
            id={id}
            statusID={statusID}
            name={statusName}
            onClick={onClick}
          />
        </GridItem>
      </GridContainer>
    </PanelContainer>
  );
}
export function ClientView ({ id, statusID, loanID, onClick }) {
  return (
    <ClientListData id={id}>
      <ConditionalWall condition={statusID !== 3}>
        <SimpleButton
          color="secondary"
          style={{ color: "white" }}
          onClick={() => {
            optionalFn(onClick)();
            const fetch = new LoanController();
            fetch.put(loanID, { creditStatusID: 3 });
          }}>
          Rechazar
        </SimpleButton>
      </ConditionalWall>
    </ClientListData>
  );
}
function LoadStatusButton ({ statusID, id, name, onClick }) {
  const [open, setOpen] = useState(0);
  const [openModal, toggleModal] = useState(0);
  return (
    <ConditionalWall
      condition={statusID === 1}
      or={
        <Typography variant="h2" style={{ textTransform: "capitalize" }}>
          {statusID === 2 ? "Autorizado" : name}
        </Typography>
      }>
      <SimpleButton
        disabled={Boolean(open)}
        onClick={() => {
          setOpen(1);
          toggleModal(1);
        }}>
        Activar
      </SimpleButton>
      <DateModal open={Boolean(openModal)} onClose={() => { setOpen(toggleModal); }} onSelect={async (myDate) => {
        toggleModal(0);
        const fetch = new LoanController();
        await fetch.put(id, {
          creditStatusID: 2, startDate: myDate,
        });
        optionalFn(onClick)(2);
      }} />
    </ConditionalWall>
  );
}

function DateModal ({ onSelect, open, onClose }) {
  const [myDate, setDate] = useState(new Date());
  return <CustomModal open={open} onClose={onClose}>
    <Card style={{
      padding: '2rem',
    }}>
      <form onSubmit={(ev) => {
        ev.preventDefault();
        onSelect(myDate);
      }}>
        <Typography variant="h2">Selecciona la fecha de pago</Typography>
        <TextField fullWidth label='Fecha' type="date"
          value={validInputDate(myDate)}
          onChange={(ev) => {
            console.log(ev.target.value);
            setDate(new Date(ev.target.value));
          }}
          InputLabelProps={{ shrink: true }}
          variant='filled'
        />
        <SimpleButton type='submit'>Seleccionar</SimpleButton>
      </form>
    </Card>
  </CustomModal>;
}