import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import { Sandbox } from "./views/Sandbox/sandbox";
import { CustomerWizard } from "./views/customer/Register/CustomerWizard";
import { InfoTable } from "./views/customer/CustomerListView/InfoTable";
import { LoanView } from "./views/credit/views/loanView";
import { CreditView } from "./views/credit/views/CreditView";
import { ClientLoanView } from "./views/account/AccountView/AccountView";
import { MiddlemanWizard } from "./views/middleman/register/MiddlemanRegister";
import {
  AgentView,
  MiddlemenTable,
} from "./views/middleman/view/MiddlemenView";
import { UsersView } from "./views/users/view/UsersViews";
import { LoginManager } from "./utils/LoginManager";
import { Dashboard } from "./views/dashboard/Dashboard";
import { InnerCustomerView } from "./views/customer/CustumerView";
import SettingsView from "./views/settings/SettingsView/index";
import { CustomerLoanRequest } from "./views/credit/register/CreditLink";
import { GlobalPayments } from "./views/credit/views/GlobalPayments";
import { PurchaseView } from "./views/purchases/purchaseContainer";

const filterByProfile = (routes) => {
  const lm = new LoginManager();
  return routes.filter((item) => {
    if (!item.profile) {
      return true;
    }
    return lm.hasPermission(item.profile);
  });
};

const dashRoutes = [
  { path: "usuarios", element: <UsersView />, profile: [1] },
  { path: "credit", element: <CreditView />, profile: [1] },
  { path: "credit/loans", element: <LoanView />, profile: [1, 3] },
  { path: "client/loan/:id", element: <ClientLoanView />, profile: [1] },
  { path: "payments", element: <GlobalPayments />, profile: [1] },
  { path: "middleman/register", element: <MiddlemanWizard />, profile: [1] },
  { path: "middleman", element: <AgentView />, profile: [1] },
  { path: "middlemen", element: <MiddlemenTable />, profile: [1] },
  { path: "customers", element: <InfoTable />, profile: [1, 3] },
  { path: "customer/request", element: <CustomerLoanRequest />, profile: [2] },
  { path: "settings", element: <SettingsView /> },
  {
    path: "customer/profile/:id",
    element: <InnerCustomerView />,
    profile: [1, 3],
  },
  { path: "dashboard", element: <Dashboard /> },
  { path: "register", element: <CustomerWizard />, profile: [1, 3] },
];

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: filterByProfile(dashRoutes),
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "register", element: <CustomerWizard /> },
      { path: "sandbox", element: <Sandbox /> },
      { path: "purchase", element: <PurchaseView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
export default routes;
