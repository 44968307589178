import { Box, Button, List, ListItem } from "@material-ui/core";
import CreditCardInput from "react-credit-card-input";
import React, { useState, useEffect, createContext, useContext } from "react";
import { PanelContainer } from "src/components/Containers/PanelContainer";
import {
  FormikSpace,
  SmartFormik,
} from "src/components/Containers/SmartFormik";
import { GridItem } from "src/components/Grid/Grid";
import { useQuery } from "src/utils/hooks/simpleHooks";
import { PurchaseModel } from "src/utils/models/PurchaseModel";
import { PurchaseController } from "./../../utils/controller/PurchaseController";
import { useCState } from "./../../utils/hooks/simpleHooks";
import { GridContainer } from "./../../components/Grid/Grid";
import { ReportsCard } from "./../reports/DashboardView/Reports";
import { ListItemText } from "@material-ui/core/";
import { ConditionalWall } from "src/components/FilterWall/ConditionalWall";
import { ConektaHelper } from "./../../utils/Utils";
import { SimpleButton, SimpleInput } from "src/components/Inputs/SimpleInput";
import swal from "sweetalert";
import { BaseApiConector } from "./../../utils/externalAPIs/BaseApiConector";
const OrderContext = createContext({});
export function PurchaseView() {
  const query = useQuery();
  const [loading, setLoading] = useState(1);
  const [state, setState] = useCState({});
  let model = new PurchaseModel();
  model.setValues(state.content);
  const loadDetails = () => {
    const fetch = new PurchaseController();
    fetch.show(query.tk).then((resp) => {
      setState(resp.data);
      setLoading(0);
    });
  };
  useEffect(loadDetails, [query.tk]);
  if (loading) {
    return <>Cargando...</>;
  }
  return (
    <OrderContext.Provider value={state}>
      <PanelContainer
        title={`Verifica tu informacion ${state.content.persona["nombres"]}`}
        style={{ width: "800px" }}>
        <GridItem>
          <FicoScore score={state.score} />
        </GridItem>
        <GridItem>
          <ConditionalWall condition={state.score.errores}>
            <SmartFormik model={model}>
              {(mods) => {
                return (
                  <FormikSpace>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button type="submit" color="primary" variant="contained">
                        Guardar
                      </Button>
                    </Box>
                  </FormikSpace>
                );
              }}
            </SmartFormik>
          </ConditionalWall>
        </GridItem>
      </PanelContainer>
    </OrderContext.Provider>
  );
}
export function FicoScore({ score }) {
  return (
    <GridContainer>
      <GridItem
        xs={6}
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          margin: "0 auto",
        }}>
        <ReportsCard
          style={{ height: "150px" }}
          title={"Fico Score"}
          icon={score.razones ? "check" : "times"}
          color={score.razones ? "green" : "red"}
          mainComponent={score.valor || score.errores[0].codigo}
          secondaryComponent={<></>}
        />
      </GridItem>
      <GridItem xs={6}>
        <h4>Razones</h4>
        <List>
          <ConditionalWall
            condition={score.razones}
            or={score.errores?.map((item, key) => (
              <ListItem key={key}>
                <ListItemText primary={item.mensaje} />
              </ListItem>
            ))}>
            {score.razones?.map((item, key) => (
              <ListItem key={key}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </ConditionalWall>
        </List>
      </GridItem>
      <GridItem style={{ display: "flex", justifyContent: "center" }}>
        <CardForm {...score} />
      </GridItem>
    </GridContainer>
  );
}
export function CardForm() {
  const { tk } = useQuery();
  const [card, setCard] = useCState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    cardHolder: "",
  });
  const purchase = new PurchaseController();
  const order = useContext(OrderContext);
  console.log(order);
  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        const conekta = new ConektaHelper();
        conekta.card = card.cardNumber;
        conekta.expireDate = card.expiry;
        conekta.cvc = card.cvc;
        conekta.cardHolder = card.cardHolder;
        conekta.tokenize(
          async (load) => {
            swal("Fantastico", "Estamos procesando tus datos", "success");
            await purchase.processPayment(tk, load.id);
            const wp = new BaseApiConector(
              `${order.content.rtn}/wc-api/complete/`,
            );
            await wp.post({
              token: order.unique,
              orderID: order.content.orderID,
            });
            window.location.replace(order.content.thanks);
          },
          (fail) => {
            console.log(fail);
            swal("Oops", fail.message_to_purchaser, "error");
          },
        );
      }}>
      <SimpleInput
        title="Nombre completo"
        value={card.cardHolder}
        onChange={({ target }) => {
          setCard({ cardHolder: target.value });
        }}
      />
      <CreditCardInput
        customTextLabels={{
          invalidCardNumber: "El número de la tarjeta es inválido",
          expiryError: {
            invalidExpiryDate: "La fecha de expiración es inválida",
            monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
            yearOutOfRange: "El año de expiración no puede estar en el pasado",
            dateOutOfRange:
              "La fecha de expiración no puede estar en el pasado",
          },
          cardNumberPlaceholder: "Número de tarjeta",
          expiryPlaceholder: "MM/AA",
          cvcPlaceholder: "COD",
        }}
        cardNumberInputProps={{
          value: card.cardNumber,
          onChange: ({ target }) => {
            setCard({ cardNumber: target.value });
          },
        }}
        cardExpiryInputProps={{
          value: card.expiry,
          onChange: ({ target }) => {
            setCard({ expiry: target.value });
          },
        }}
        cardCVCInputProps={{
          value: card.cvc,
          onChange: ({ target }) => {
            setCard({ cvc: target.value });
          },
        }}
        fieldClassName="input"
      />
      <SimpleButton type="submit">ok</SimpleButton>
    </form>
  );
}
