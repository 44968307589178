export class BaseModel {
  getKeys() {
    return Object.keys(this.values);
  }
  getValues() {
    return this.values;
  }
  setAttrs(values) {
    this.values = { ...this.values, ...values };
    return this;
  }
  setValues(values) {
    for (let key in values) {
      const item = values[key];
      if (this.values[key]) {
        this.values[key].value = item;
      }
    }

    return this;
  }
  getDefaultValues() {
    const defaultValues = {};
    for (let item in this.values) {
      defaultValues[item] = this.values[item].value || "";
    }
    return defaultValues;
  }
  getYupValidations() {
    const defaultValues = {};
    for (let item in this.values) {
      let validation = this.values[item].validation;
      if (!validation) continue;
      defaultValues[item] = validation;
    }
    return defaultValues;
  }
  setComponent(key, component) {
    this.values[key] = component;
  }
  __yupValidation() {}
}
