import { BaseModel } from "./BaseModel";
import * as Yup from "yup";
export class InfoModel extends BaseModel {
  regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  values = {
    name: {
      title: "Nombre",
      validation: Yup.string().max(255).required("Nombre es requerido"),
    },
    surname: {
      title: "Apellido",
      validation: Yup.string().max(255).required("apellido es requerido"),
    },
    curp: {
      title: "CURP",
      validation: Yup.string()
        .max(18, "No es un curp valido")
        .min(18, "no es un curp valido")
        .required("curp es requerido")
        .matches(this.regex),
    },
    email: {
      title: "Correo Electronico",
      value: "",
      validation: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    },
    phones: {
      title: "Telefono",
      type: "tel",
      validation: Yup.string().max(255).required("telefono es requerido"),
    },
    street: {
      title: "Calle",
      validation: Yup.string().max(255).required("Calle es requerida"),
    },
    ext: {
      title: "Numero exterior",
      validation: Yup.string()
        .max(255)
        .required("Numero exterior es requerido"),
    },
    interior: {
      title: "Numero Interior",
    },
    colony: {
      title: "Colonia",
      validation: Yup.string().max(255).required("Colonia es requerida"),
    },
    zip: {
      title: "Codigo Postal",
      validation: Yup.string().max(255).required("Codigo postal es requerido"),
    },
    city: {
      title: "Ciudad",
      validation: Yup.string().max(255).required("Ciudad es requerida"),
    },
    state: {
      title: "Estado",
      validation: Yup.string().max(255).required("Estado es requerido"),
    },
    rfc: {
      title: "R.F.C",
      validation: Yup.string().max(255).required("R.f.C es requerido"),
    },
    amountRequired: {
      title: "Cantidad a Solicitar",
      type: "number",
      validation: Yup.string().max(255).required("Cantidad es requerida"),
    },
    verifiableIncome: {
      title: "Ingresos Verificables",
      type: "number",
      validation: Yup.string().max(255).required("Los Ingresos son requeridos"),
    },
  };
}
