import { BaseModel } from "./BaseModel";
import * as Yup from "yup";
export class CreditLinkModel extends BaseModel {
  values = {
    infoID: {
      title: "Cliente",
      validation: Yup.number().required("Cliente es requerido"),
    },
    creditID: {
      title: "Tipo de credito",
      validation: Yup.number().required("Credito es requerido"),
    },
    amount: {
      title: "Cantidad",
      type: "number",
      value: "0",
      validation: Yup.number()
        .min(1, "El prestamo debe de ser al menos 1 peso")
        .required("Numero es requerida"),
    },

    periodType: {
      title: "Pago en ",
      validation: Yup.string().max(255).required("campo requerido"),
    },
  };
}
