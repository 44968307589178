import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { LoginManager } from "./../../utils/LoginManager";
import { DevicesController } from "./../../utils/controller/DevicesController";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));
const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();
  const { logStatus } = checkLogginStatus(navigate);

  const devices = new DevicesController();
  devices.register();
  if (!logStatus) {
    return <>Cargando...</>;
  }
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
function checkLogginStatus(navigate) {
  const logged = new LoginManager();
  let logStatus = true;
  if (!logged.isLogged()) {
    logStatus = false;
    navigate("/login", { replace: true });
  }
  const interval = setInterval(() => {
    if (!logged.isLogged()) {
      logStatus = false;
      navigate("/login", { replace: true });
    }
  }, 1000);
  return { interval, logStatus };
}
export default DashboardLayout;
