import { BaseModel } from "./BaseModel";
import * as Yup from "yup";
export class MiddlemanModel extends BaseModel {
  values = {
    name: {
      title: "Nombre",
      validation: Yup.string().max(255).required("Nombre es requerido"),
    },
    surname: {
      title: "Apellido",
      validation: Yup.string().max(255).required("Apellido es requerido"),
    },
  };
}
