import { BaseModel } from "./BaseModel";
import * as Yup from "yup";
export class ReferenceModel extends BaseModel {
  values = {
    name: {
      title: "Nombre",
      validation: Yup.string().max(255).required("Nombre es requerido"),
    },
    surname: {
      title: "Apellido",
      validation: Yup.string().max(255).required("Apellido es requerido"),
    },
    phones: {
      title: "Telefono",
      validation: Yup.string().max(255).required("Telefono es requerido"),
    },
    email: {
      title: "Correo Electronico",
      validation: Yup.string().max(255).required("Correo es requerido"),
    },
  };
}
