import { BaseController } from "./BaseController";
import { AuthFetch } from "./../AuthFetch";
export class PaymentController extends BaseController {
  path = "payment";

  getPayments(id) {
    const fetch = new AuthFetch(`${this.path}s/${id}`);
    return fetch.get();
  }

  get(values) {
    const fetch = new AuthFetch(`${this.path}s/`);
    return fetch.get(values);
  }
}
